import { defineStore } from 'pinia';

import { useArticlesStore } from '@/store-v2/articles.store.js';
import { useAuthStore } from '@/store-v2/auth.store.js';

import {
  doGetMostPopular,
  doGetMyFeed,
  doGetPretestOnboarding,
  doPostIncrementShareCounter,
  doSearchArticlesByText,
  doShowEvaluation,
  doShowPretestUser,
} from '@/api/feed';
import { isSuccessfulResponse } from '@/api/utilities.ts';

export const useFeedStore = defineStore('feed', {
  state: () => ({
    showPretest: false,
    articleId: 0,
    checkIfHasToShowPretestOnboarding: true,
    showPretestResult: false,
    showPretestOnboarding: true,
    articlesTakenToRemoveFromKeepAliveFeed: [],
    articlesWithAbtractExpanded: [],
  }),
  actions: {
    increaseShareCounter(submissionId) {
      return doPostIncrementShareCounter(submissionId);
    },
    async getMostPopular(nextPagination) {
      let response = await doGetMostPopular(nextPagination);

      if (isSuccessfulResponse(response)) {
        response = await this.handleGuestQuizzes(response.data);

        // TODO: to be removed when the single feed is ready
        response = await this.handleVisitedArticles(response);
      }

      return response;
    },
    async getMyFeed(nextPagination) {
      let response = await doGetMyFeed(nextPagination);

      if (isSuccessfulResponse(response)) {
        response = await this.handleGuestQuizzes(response.data);
        response = await this.handleVisitedArticles(response);
      }

      return response;
    },
    async searchArticlesByText(params) {
      let response = await doSearchArticlesByText(params);

      if (isSuccessfulResponse(response)) {
        response = await this.handleGuestQuizzes(response.data);
        response = await this.handleVisitedArticles(response);
      }

      return response;
    },
    handleGuestQuizzes(articles) {
      let tempArticles = articles.items;
      let guestQuizzes = JSON.parse(localStorage.getItem('acapedia_guest_quizzes')) ?? [];

      if (guestQuizzes.length) {
        guestQuizzes = guestQuizzes.map((e) => parseInt(e.articleId));
        tempArticles = tempArticles?.filter((article) => {
          return guestQuizzes.indexOf(article.id) < 0;
        });
      }

      return {
        items: tempArticles ?? [],
        previousPagination: articles.previousPagination,
        nextPagination: articles.nextPagination,
        total: articles.total,
      };
    },
    handleVisitedArticles(articles) {
      let tempArticles = articles.items;
      const visitedArticles = JSON.parse(localStorage.getItem('acapedia_user_visited_articles')) ?? [];

      if (visitedArticles.length) {
        tempArticles = tempArticles?.map((article) => {
          article.visited = visitedArticles.includes(article.id);

          return article;
        });
      }

      return {
        items: tempArticles ?? [],
        previousPagination: articles.previousPagination,
        nextPagination: articles.nextPagination,
        total: articles.total,
      };
    },
    showPretestUser() {
      return doShowPretestUser();
    },
    setArticleIdToPretest(id) {
      this.articleId = id;
    },
    async hasToShowPretestOnboarding() {
      const response = await doGetPretestOnboarding();
      if (isSuccessfulResponse(response)) {
        this.checkIfHasToShowPretestOnboarding = response.data.onboarding === '1';
      }
    },
    // TODO: delete this plain setter
    setShowPretestResult(isShowed) {
      this.showPretestResult = isShowed;
    },
    // TODO: delete this plain setter
    setShowPretestOnboarding(isShowed) {
      this.showPretestOnboarding = isShowed;
    },
    removeArticleFromKeepAliveFeed(articleId) {
      this.articlesTakenToRemoveFromKeepAliveFeed = Array.from(
        new Set([...this.articlesTakenToRemoveFromKeepAliveFeed, parseInt(articleId)]),
      );
    },
    resetArticlesTakenFromKeepAliveFeedMemory() {
      this.articlesTakenToRemoveFromKeepAliveFeed = [];
    },
    showEvaluation() {
      return doShowEvaluation();
    },
    addArticleWithAbtractExpanded(articleId) {
      this.articlesWithAbtractExpanded.push(articleId);
    },
    removeArticleWithAbstractExpanded(articleId) {
      const elementIndex = this.articlesWithAbtractExpanded.indexOf(articleId);
      if (elementIndex !== -1) {
        this.articlesWithAbtractExpanded.splice(elementIndex, 1);
      }
    },
    /**
     * Checks if pretest should be shown for the given article
     * @param {Object} params - The parameters object
     * @param {string} [params.articleId] - The ID of the article
     * @param {string} [params.articleSlug] - The slug of the article
     * @returns {Promise<boolean>} Promise that resolves to whether pretest should be shown
     */
    async checkIfHasToShowPretest({ articleId, articleSlug }) {
      const isLoggedInUser = useAuthStore().isLoggedInUser;
      if (!isLoggedInUser) return false;

      const response = await doShowPretestUser();
      const isTimeToShowPretest = isSuccessfulResponse(response) && response.data?.showPretest;
      if (!isTimeToShowPretest) return false;

      const quiz = await useArticlesStore().fetchArticleQuizForPretest({ articleId, articleSlug });
      const articleHasPretest = quiz.questions?.some((question) => question.pretest);
      if (!articleHasPretest) return false;

      return true;
    },
  },
  getters: {
    isOnboardingPretestShow: (state) => state.showPretest,
    getArticleId: (state) => state.articleId,
    isOnboardingPretestToShowAgain: (state) => state.checkIfHasToShowPretestOnboarding,
    isShowPretestResult: (state) => state.showPretestResult,
    isShowPretestOnboarding: (state) => state.showPretestOnboarding,
    getArticlesTakenToRemoveFromKeepAliveFeed: (state) => state.articlesTakenToRemoveFromKeepAliveFeed,
    getArticlesWithAbtractExpanded: (state) => state.articlesWithAbtractExpanded,
  },
});
