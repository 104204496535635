import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import { useAuthStore } from '@/store-v2/auth.store';

export async function initializeSessionGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  const token = to.query.token as string | undefined;
  if (!token) return next();

  try {
    const authStore = useAuthStore();
    await authStore.initializeSession(token);
    const nextRoute = from.meta?.nextRoute ?? (to.query?.nextRoute as string | undefined) ?? 'home';
    return next({ name: nextRoute });
  } catch (error) {
    console.error('Error during token initialization:', error);
    return next();
  }
}
