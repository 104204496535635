export const VUETIFY_BREAKPOINTS = {
  // Viewport calculations always start at 0 and work their way up.
  // A value of 374 for the xxs threshold means that a window size of 0 to 374
  // is considered to be in this breakpoint
  xxs: 374,
  // realistically an ipad or tablet might not grab from this point forward but a bit more
  xs: 767,
  sm: 1022,
  md: 1439,
  // todo: it seems we are adding this new breakpoint to the design but this may break a lot of things that depends on the md and up
  lg: 1900,
};

export const ACAPEDIA_BREAKPOINTS = {
  smallMobile: 0,
  mobile: 374,
  tablet: 767,
  smallDesktop: 1022,
  desktop: 1439,
  largeDesktop: 1900,
};

export const MAX_DOWN_VOTES_CHARS_ALLOWED_WITHOUT_DISPLACE = 7;
