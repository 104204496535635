import { generateUUIDv4 } from '@/util';

const STORAGE_KEY = 'ffId';

export const getOrCreateDeviceId = () => {
  const storedDeviceId = localStorage.getItem(STORAGE_KEY);

  if (storedDeviceId) return storedDeviceId;

  const uuid = generateUUIDv4();
  localStorage.setItem(STORAGE_KEY, uuid);

  return uuid;
};
