import { VUETIFY_BREAKPOINTS } from '@/constants';

const VIEW_PORTS = {
  smallMobile: 'smallMobile',
  mobile: 'mobile',
  tablet: 'tablet',
  smallDesktop: 'smallDesktop',
  desktop: 'desktop',
  largeDesktop: 'largeDesktop',
};

const VIEW_PORTS_ORDERED_BY_SIZE = [
  VIEW_PORTS.smallMobile,
  VIEW_PORTS.mobile,
  VIEW_PORTS.tablet,
  VIEW_PORTS.smallDesktop,
  VIEW_PORTS.desktop,
  VIEW_PORTS.largeDesktop,
];

const upperCaseFirstLetter = (string) => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

export function vuetifyBreakpointCalculator(width) {
  const breakpoint = {};

  const SMALL_MOBILE_THRESHOLD = VUETIFY_BREAKPOINTS.xxs;
  const isSmallMobileBreakpoint = width <= SMALL_MOBILE_THRESHOLD;

  if (isSmallMobileBreakpoint) {
    generateReadableBreakpointAliases(VIEW_PORTS.smallMobile);
    return breakpoint;
  }

  const MOBILE_THRESHOLD = VUETIFY_BREAKPOINTS.xs;
  const isMobileBreakpoint = width > SMALL_MOBILE_THRESHOLD && width <= MOBILE_THRESHOLD;

  if (isMobileBreakpoint) {
    generateReadableBreakpointAliases(VIEW_PORTS.mobile);
    return breakpoint;
  }

  const TABLET_THRESHOLD = VUETIFY_BREAKPOINTS.sm;
  const isTabletBreakpoint = width > MOBILE_THRESHOLD && width <= TABLET_THRESHOLD;

  if (isTabletBreakpoint) {
    generateReadableBreakpointAliases(VIEW_PORTS.tablet);
    return breakpoint;
  }

  const SMALL_DESKTOP_THRESHOLD = VUETIFY_BREAKPOINTS.md;
  const isSmallDesktopBreakpoint = width > TABLET_THRESHOLD && width <= SMALL_DESKTOP_THRESHOLD;

  if (isSmallDesktopBreakpoint) {
    generateReadableBreakpointAliases(VIEW_PORTS.smallDesktop);
    return breakpoint;
  }

  const isDesktopBreakpoint = width > SMALL_DESKTOP_THRESHOLD;

  if (isDesktopBreakpoint) {
    generateReadableBreakpointAliases(VIEW_PORTS.desktop);
    return breakpoint;
  }

  function generateReadableBreakpointAliases(viewPort) {
    const viewPortIndex = VIEW_PORTS_ORDERED_BY_SIZE.indexOf(viewPort);

    generateReadableBreakpointsForSmallerViewPorts(viewPortIndex);
    generateReadableBreakpointForCurrentViewPort(viewPort);
    generateReadableBreakpointsForBiggerViewPorts(viewPortIndex);
  }

  function generateReadableBreakpointsForSmallerViewPorts(currentViewPort) {
    const smallerViewPorts = VIEW_PORTS_ORDERED_BY_SIZE.slice(0, currentViewPort);

    smallerViewPorts.forEach(generateAliasesOnlyUpActivated);
  }

  function generateAliasesOnlyUpActivated(viewPort) {
    breakpoint[viewPort] = false;
    breakpoint[`is${upperCaseFirstLetter(viewPort)}`] = false;
    breakpoint[`${viewPort}Only`] = false;
    breakpoint[`${viewPort}AndDown`] = false;
    breakpoint[`${viewPort}AndUp`] = true;
  }

  function generateReadableBreakpointForCurrentViewPort(currentViewPort) {
    generateAliasesAllActivated(currentViewPort);
  }

  function generateAliasesAllActivated(viewPort) {
    breakpoint[viewPort] = true;
    breakpoint.name = viewPort;
    breakpoint[`is${upperCaseFirstLetter(viewPort)}`] = true;
    breakpoint[`${viewPort}Only`] = true;
    breakpoint[`${viewPort}AndDown`] = true;
    breakpoint[`${viewPort}AndUp`] = true;
  }

  function generateReadableBreakpointsForBiggerViewPorts(currentViewPort) {
    const biggerViewPorts = VIEW_PORTS_ORDERED_BY_SIZE.slice(currentViewPort + 1);

    biggerViewPorts.forEach(generateAliasesOnlyDownActivated);
  }

  function generateAliasesOnlyDownActivated(viewPort) {
    breakpoint[viewPort] = false;
    breakpoint[`is${upperCaseFirstLetter(viewPort)}`] = false;
    breakpoint[`${viewPort}Only`] = false;
    breakpoint[`${viewPort}AndDown`] = true;
    breakpoint[`${viewPort}AndUp`] = false;
  }
}
