import { isProductionEnv } from '@/util';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { event as gtagEvent, purchase as gtagPurchase, set as gtagSet } from 'vue-gtag';

const slackNotifications = () => import('@/api/slackNotifications');

export function useEventNotifier() {
  function login({ method }: { method: string }) {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=12777964546362446356-SA&client_type=gtag#login
    gtagEvent('login', { method });
  }

  function logout() {
    notify('logout', {
      eventCategory: 'Logout',
      eventLabel: 'User Logout',
    });
  }

  function notify(
    eventName: string,
    { eventCategory, eventLabel }: { eventCategory?: string; eventLabel?: string } = {},
  ) {
    const gtagParams: Record<string, string> = {};

    if (eventCategory) {
      gtagParams.event_category = eventCategory;
    }

    if (eventLabel) {
      gtagParams.event_label = eventLabel;
    }

    gtagEvent(eventName, gtagParams);
  }

  async function notifyFailedPayment(userEmail: string) {
    try {
      const { notifyFailedPayment: notifyFailedPaymentOnSlack } = await slackNotifications();

      await notifyFailedPaymentOnSlack({
        userEmail: userEmail,
      });
    } catch (error) {
      console.error('Error sending failed payment notification', error);
    }
  }

  function purchase({
    currency,
    transactionId,
    value,
    items,
  }: {
    currency: string;
    transactionId: string;
    value: number;
    items: {
      id: string;
      itemName: string;
      itemCategory: string;
      quantity: number;
      price: number;
    }[];
  }) {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=12777964546362446356-SA&client_type=gtag#purchase
    const gtagPurchaseParams = {
      currency: currency,
      transaction_id: transactionId,
      // The monetary value of the event.
      value: value,
      items: items.map((item) => ({
        id: item.id,
        item_name: item.itemName,
        item_category: item.itemCategory,
        quantity: item.quantity,
        price: item.price,
      })),
    };

    gtagPurchase(gtagPurchaseParams);

    // We want to track our custom event as well
    notify('purchase_plan', {
      eventCategory: 'Purchase Plan',
      eventLabel: items[0].itemName,
    });
  }

  async function register({
    method,
    hashedEmail,
    hashedFirstName,
    hashedLastName,
    hashedPhoneNumber,
    hashedDateOfBirth,
  }: {
    method: string;
    hashedEmail: string;
    hashedFirstName: string;
    hashedLastName: string;
    hashedPhoneNumber: string;
    hashedDateOfBirth: string;
  }) {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=12777964546362446356-SA&client_type=gtag#sign_up
    gtagEvent('sign_up', { method });

    await trackRegisterConversionOnPlatforms({
      hashedEmail,
      hashedFirstName,
      hashedLastName,
      hashedPhoneNumber,
      hashedDateOfBirth,
    });
  }

  async function trackRegisterConversionOnPlatforms({
    hashedEmail,
    hashedFirstName,
    hashedLastName,
    hashedPhoneNumber,
    hashedDateOfBirth,
  }: {
    hashedEmail: string;
    hashedFirstName: string;
    hashedLastName: string;
    hashedPhoneNumber: string;
    hashedDateOfBirth: string;
  }) {
    if (!isProductionEnv) {
      console.warn('"register" conversion was not tracked because we are not in production');
      return;
    }

    try {
      trackRegisterConversionOnGtag();
      trackRegisterConversionOnLinkedin();
      trackRegisterConversionOnTwitter();
      await trackRegisterConversionOnMeta({
        hashedEmail,
        hashedFirstName,
        hashedLastName,
        hashedPhoneNumber,
        hashedDateOfBirth,
      });
    } catch (error) {
      console.error('Error tracking signup conversion', error);
      Sentry.captureException(error);
    }
  }

  function trackRegisterConversionOnGtag() {
    window.gtag_report_conversion();
    console.info('Gtag "register" conversion tracked');
  }

  function trackRegisterConversionOnLinkedin() {
    // window.lintrk('track', { conversion_id: 14927017 });
    // console.info('LinkedIn "register" conversion tracked');
  }

  async function trackRegisterConversionOnMeta({
    hashedEmail,
    hashedFirstName,
    hashedLastName,
    hashedPhoneNumber,
    hashedDateOfBirth,
  }: {
    hashedEmail: string;
    hashedFirstName: string;
    hashedLastName: string;
    hashedPhoneNumber: string;
    hashedDateOfBirth: string;
  }) {
    const access_token = import.meta.env.VITE_FACEBOOK_CONVERSION_ACCESS_TOKEN;
    const api_version = 'v17.0';
    const pixel_id = import.meta.env.VITE_FACEBOOK_CONVERSION_PIXEL_ID;
    const url = `https://graph.facebook.com/${api_version}/${pixel_id}/events?access_token=${access_token}`;
    const unixTimestampInSeconds = Math.floor(Date.now() / 1000);

    const userData: Record<string, string[]> = {};

    if (hashedEmail) {
      userData.em = [hashedEmail];
    }

    if (hashedFirstName) {
      userData.fn = [hashedFirstName];
    }

    if (hashedLastName) {
      userData.ln = [hashedLastName];
    }

    if (hashedPhoneNumber) {
      userData.ph = [hashedPhoneNumber];
    }

    if (hashedDateOfBirth) {
      userData.db = [hashedDateOfBirth];
    }

    const requestData = {
      data: [
        {
          action_source: 'website',
          event_name: 'Complete registration',
          event_time: unixTimestampInSeconds,
          user_data: userData,
        },
      ],
    };

    await axios.post(url, requestData);
    console.info('Facebook "register" conversion tracked');
  }

  function trackRegisterConversionOnTwitter() {
    // Disable this because we are not using twitter tracking
    return;

    window.twq('event', 'tw-ogs0r-ogs1g', {
      conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
    });
    console.info('Twitter "register" conversion tracked');
  }

  type UserProperties = {
    user_id?: string;
    user_email?: string;
    user_current_plan?: string;
    user_browser_width?: number;
    user_browser_height?: number;
    user_browser_size?: string;
  };

  function setUser({ id, email, currentPlanName }: { id?: string; email?: string; currentPlanName?: string } = {}) {
    const userProperties: UserProperties = {};

    if (id) {
      // https://www.analyticsmania.com/post/google-analytics-4-user-id
      userProperties.user_id = id;
    }

    if (email) {
      userProperties.user_email = email;
    }

    if (currentPlanName) {
      userProperties.user_current_plan = currentPlanName;
    }

    setBrowserSize(userProperties);

    gtagSet({
      user_properties: userProperties,
    });
  }

  function setBrowserSize(userProperties: UserProperties) {
    const width = window.innerWidth;
    const height = window.innerHeight;

    userProperties.user_browser_width = width;
    userProperties.user_browser_height = height;
    userProperties.user_browser_size = `${width}x${height}`;
  }

  function unsetUser() {
    gtagSet({ user_properties: null });
  }

  function viewRegister() {
    trackViewRegisterConversionOnPlatforms();
  }

  function trackViewRegisterConversionOnPlatforms() {
    if (!isProductionEnv) {
      console.warn('The "view register" conversion was not tracked because we are not in production');
      return;
    }

    trackViewRegisterConversionOnGtag();
    trackViewRegisterConversionOnLinkedin();
    trackViewRegisterConversionOnTwitter();
    trackViewRegisterConversionOnMeta();
  }

  function trackViewRegisterConversionOnGtag() {
    window.gtag_report_view_register_conversion();
    console.info('Gtag "view register" conversion tracked');
  }

  function trackViewRegisterConversionOnLinkedin() {
    // window.lintrk('track', { conversion_id: 14291796 });
    console.info('LinkedIn "view register" conversion tracked');
  }

  function trackViewRegisterConversionOnTwitter() {
    // Disable this because we are not using twitter tracking
    return;

    window.twq('event', 'tw-ogs0r-ogs13', {
      conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
    });
    console.info('Twitter "view register" conversion tracked');
  }

  function trackViewRegisterConversionOnMeta() {
    window.fbq('track', 'AddToWishlist');
    console.info('Facebook "view register" conversion tracked');
  }

  return {
    login,
    logout,
    notify,
    notifyFailedPayment,
    purchase,
    register,
    setUser,
    unsetUser,
    viewRegister,
  };
}
