import { type Router } from 'vue-router';
import router from '@/router/router.ts';
import axios, { type AxiosError, type AxiosRequestConfig } from 'axios';

import { useAuthStore } from '@/store-v2/auth.store';

import { FEATURE_FLAGS, getFeatureFlagProvider } from '@/lib/featureFlag';

import { isContentTypeJsonOfError, isUnauthenticatedError } from './utilities.ts';

export function setUpApiInterceptors() {
  axios.defaults.baseURL = import.meta.env.VITE_API_URL;

  axios.interceptors.request.use(
    function (config) {
      const token = useAuthStore().authToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      // TODO: Address technical debt related to setting feature flags as headers
      // Currently, feature flags are set as headers for easy integration without backend changes.
      // However, this approach introduces a limitation where the header values could be modified by users.
      // Implementing Split.io on the backend would provide a more robust solution.
      // However, it requires significant additional work, including modifying the infrastructure and introducing Redis as a dependency.
      // Given the time constraints, the decision was made to proceed with the current approach.
      // It is crucial to prioritize resolving this limitation in the future to ensure optimal functionality.
      // Please note that this implementation should be reviewed and enhanced accordingly.
      setFeatureFlagsAsHeader(config);

      return config;
    },
    function (err) {
      return Promise.reject(err);
    },
  );

  const setFeatureFlagsAsHeader = (axiosRequestConfig: AxiosRequestConfig) => {
    const featureFlagProvider = getFeatureFlagProvider();

    FEATURE_FLAGS.forEach((flag) => {
      const formattedFlagName = flag.toLowerCase().replace(/_/g, '-');

      if (axiosRequestConfig.headers) {
        axiosRequestConfig.headers[`x-${formattedFlagName}`] = featureFlagProvider.isFeatureEnabled(flag);
      }
    });
  };

  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return Promise.resolve(response);
  }, axiosResponseErrorInterceptor);
}

export function axiosResponseErrorInterceptor(err: AxiosError) {
  if (!err.response) {
    return Promise.reject(err);
  }

  return isContentTypeJsonOfError(err) ? interceptJsonError(err) : interceptHtmlError(err);
}

const interceptJsonError = (err: AxiosError) => {
  if (isExpiredTokenError(err)) {
    logoutAndRedirectToLogin();
  }

  throw err;
};

const isExpiredTokenError = (err: AxiosError) => isUnauthenticatedError(err) && !isLoginOrRegisterRoute(router);

const isLoginOrRegisterRoute = (router: Router) => {
  const { name: currentRouteName } = router.currentRoute.value;
  return currentRouteName === 'login' || currentRouteName === 'register';
};

const logoutAndRedirectToLogin = () => {
  useAuthStore().localLogout();

  router.push({
    name: 'login',
  });
};

const interceptHtmlError = (err: AxiosError) => {
  // In case the response comes from an actual render page then we proceed as follows
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  const statusCode = err.response?.status ?? 500;
  let message = null;

  // don't show the 429 error, about too many requests
  if (statusCode === 429) {
    return;
  }

  switch (statusCode) {
    case 400:
      message = "We couldn't process your request.";
      break;

    case 401:
      message = 'The page you are looking for requires special authorization';
      break;

    case 403:
      message = 'The action you attempted was forbidden';
      break;

    case 404:
      message = 'The page you are looking for is not available';
      break;

    case 422:
      message = "We couldn't process your request";
      break;

    case 500:
      message = 'Internal server error';
      break;

    default:
      message = 'This is an unknown error';
      break;
  }

  // Finally we show the error page with the specific error code and message
  if (statusCode) {
    router.push({
      name: 'error',
      state: { statusCode: statusCode, title: message },
    });
  }

  // we have to reject the promise here, otherwise the promise will be resolved with undefined
  return Promise.reject(err);
};
