/* eslint-disable @typescript-eslint/no-explicit-any */

import * as Sentry from '@sentry/browser';
import { type AxiosError, type AxiosResponse } from 'axios';

import { appToast } from '@/lib/toast/toast.ts';

export const isSuccessfulResponse = (response: AxiosResponse) => response?.status >= 200 && response?.status <= 299;
const NETWORK_ERROR_MESSAGE = 'Network error. Please check your internet connection';

// TODO: Handle when the server responds with an html with the error (such as non existing endpoint)
// Note: the error handler force the upper levels to use a try-catch due that is throwing the error again and not catching it
export const responseHandler = async <T extends AxiosResponse, R>(
  promise: Promise<T>,
  {
    hasToShowToasts = true,
    hasToShowSuccessToasts = false,
    hasToShowErrorToasts = true,
    isPaginated = false,
    responseDataGetter = null,
    customErrorMessage = null,
  }: {
    hasToShowToasts?: boolean;
    hasToShowSuccessToasts?: boolean;
    hasToShowErrorToasts?: boolean;
    isPaginated?: boolean;
    responseDataGetter?: ((response: T) => R) | null;
    customErrorMessage?: string | null;
  } = {},
) => {
  try {
    const response = await promise;
    const successApiMessage = response.data.message;
    showSuccessMessage(successApiMessage, { hasToShowToasts, hasToShowSuccessToasts });

    responseDataGetter ??= isPaginated ? getPaginatedResponseData : getResponseData;
    return responseDataGetter(response);
  } catch (error) {
    const axiosError = error as AxiosError;
    handleErrorResponse(axiosError, { customErrorMessage, hasToShowToasts, hasToShowErrorToasts });

    throw error;
  }
};

const showSuccessMessage = (successMessage: any, { hasToShowToasts, hasToShowSuccessToasts }: any) => {
  if (!hasToShowToasts || !hasToShowSuccessToasts) return;

  appToast.success(successMessage);
};

function handleErrorResponse(error: AxiosError, { customErrorMessage, hasToShowToasts, hasToShowErrorToasts }: any) {
  console.error(error);
  captureErrorInSentry(error);

  if (isContentTypeJsonOfError(error)) {
    handleJsonError(error, { customErrorMessage, hasToShowToasts, hasToShowErrorToasts });
  } else if (isNetworkError(error)) {
    handleNetworkError();
  }
}

function captureErrorInSentry(error: AxiosError): void {
  if (!shouldCaptureInSentry(error)) return;

  Sentry.captureException(error);
}

function shouldCaptureInSentry(error: AxiosError) {
  if (isNetworkError(error)) return false;

  if (isServerError(error)) return true;

  const specialCases = [
    429, // Rate limiting
    413, // Payload too large
  ];

  if (specialCases.includes(error.response!.status)) return true;

  return false;
}

function handleJsonError(error: any, { customErrorMessage, hasToShowToasts, hasToShowErrorToasts }: any) {
  if (isInternalServerError(error)) {
    handleInternalServerError();
  } else {
    const errorMessage = customErrorMessage ? customErrorMessage : getErrorMessage(error);

    showErrorMessage(errorMessage, { hasToShowToasts, hasToShowErrorToasts });
  }
}

function handleNetworkError() {
  showErrorMessage(NETWORK_ERROR_MESSAGE, {
    hasToShowToasts: true,
    hasToShowErrorToasts: true,
  });
}

function handleInternalServerError() {
  const internalServerErrorPath = '/error/500';

  // We don't use the router here
  // because we get a circular dependency error
  if (window.location.pathname === internalServerErrorPath) return;

  window.location.href = internalServerErrorPath;
}

const getErrorMessage = (error: any) => {
  let firstSpecificApiErrorMessage = null;
  const errors = error.response?.data?.errors;

  if (errors) {
    const errorMessages = Object.values(errors);
    firstSpecificApiErrorMessage = errorMessages.flat()?.[0];
  }

  const errorApiMessage = firstSpecificApiErrorMessage ?? error.response?.data?.message;
  const otherErrorMessage = error.message;

  return errorApiMessage ?? otherErrorMessage;
};

const showErrorMessage = (errorMessage: any, { hasToShowToasts, hasToShowErrorToasts }: any) => {
  if (!hasToShowToasts || !hasToShowErrorToasts) return;

  if (errorMessage === NETWORK_ERROR_MESSAGE) return showNetworkErrorMessage(errorMessage);

  appToast.error(errorMessage);
};

const showNetworkErrorMessage = (errorMessage: string) => {
  const FIVE_SECONDS_IN_MS = 5000;
  const currentTime = new Date().getTime();
  // NOW: FIX time since last network error
  // const timeSinceLastNetworkError = currentTime - (Vue.toasted.global.lastTimeOfLastNetworkError ?? 0);
  const timeSinceLastNetworkError = currentTime - 0;
  const isLongEnoughSinceLastNetworkError = timeSinceLastNetworkError > FIVE_SECONDS_IN_MS;

  if (!isLongEnoughSinceLastNetworkError) return;

  // To avoid showing the same network error message multiple times
  // NOW: FIX time since last network error
  // Vue.toasted.global.lastTimeOfLastNetworkError = currentTime;
  appToast.error(errorMessage);
};

/**
 * @deprecated This function is no longer supported and should not be used.
 * Please use the `responseHandler` function instead.
 */
export const toastHandler = (
  promise: Promise<AxiosResponse>,
  showToast = true,
  showOnlyToastErrors = false,
  responseDataGetter = getResponseDataVOld,
) => {
  return promise
    .then((response) => {
      if (showToast && !showOnlyToastErrors) {
        appToast.success(response.data.message);
      }

      return responseDataGetter(response);
    })
    .catch((error) => {
      const customErrorMsg = error.response?.data;

      if (showToast || showOnlyToastErrors) {
        appToast.error(customErrorMsg ? customErrorMsg.message : error.message);
      }

      if (customErrorMsg) {
        return error.response;
      }

      return error;
    });
};

/**
 * @deprecated This function is no longer supported and should not be used.
 * Please use `getResponseData` function instead or `getPaginatedResponseData` if it is paginated.
 * Remember to wrap the api response in a envelope from the backend side using a "data" key.
 *
 * This function returns the response as it is because there are components using the Axios response.
 */
export const getResponseDataVOld = (response: AxiosResponse) => response;
export const getResponseDataNoContent = (response: AxiosResponse) => response;
export const getResponseDataNotWrapped = <
  T extends AxiosResponse | { data: any },
  R = T extends AxiosResponse ? T['data'] : T extends { data: infer D } ? D : never,
>(
  response: T,
): R => {
  return response.data as R;
};

export const getResponseData = ({ data }: any) => data.data;
export const getPaginatedResponseData = ({ data }: any) => {
  const nextCursorPagination = data.links.next?.split('cursor=')[1];
  const previousCursorPagination = data.links.prev?.split('cursor=')[1];

  return {
    data: data.data,
    nextCursorPagination,
    previousCursorPagination,
  };
};

export const getContentTypeOfError = (error: any) => {
  if (!error.response?.headers) {
    return [];
  }

  return error.response.headers['content-type'];
};

export const isContentTypeJsonOfError = (error: AxiosError) =>
  getContentTypeOfError(error).indexOf('application/json') !== -1;
export const isInternalServerError = (error: AxiosError) => error.response?.status === 500;

const isServerError = (error: AxiosError) =>
  error.response && error.response.status >= 500 && error.response.status < 600;
export const isNetworkError = (error: AxiosError) => !error.response;
export const isUnauthenticatedError = (error: AxiosError) => error.response?.status === 401;
