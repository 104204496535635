import axios from 'axios';

import { getResponseDataVOld, responseHandler, toastHandler } from '@/api/utilities.ts';

const doGetProfileDetails = () => {
  return axios.get('/user/profile');
};

const doUpdateEmail = (payload) => {
  return toastHandler(axios.post('/user/profile/update-email', payload), true);
};

const doGetUserPaymentMethod = () => {
  return responseHandler(axios.get('/user/billing/payment-method'), {
    responseDataGetter: getResponseDataVOld,
  });
};

const doUpdateBillingAddress = (payload) => {
  return axios.post('/user/billing/address/update', payload);
};

const doDeletePaymentMethod = () => {
  return axios.delete('/user/billing/payment-method');
};

const doPostPaymentMethod = (payload) => {
  return axios.post('/user/billing/payment-method', payload);
};

const doGetPaymentHistory = () => {
  return responseHandler(axios.get('/user/billing/payment/history'), {
    hasToShowToasts: false,
    responseDataGetter: getResponseDataVOld,
  });
};

const doGetPaymentReceiptPdf = (payload) => {
  return axios.get('/user/billing/payment/history/receipt/pdf', payload);
};

const doUpdateProfileNames = (payload) => {
  return responseHandler(axios.post('/user/profile/update-names', payload), {
    hasToShowToasts: false,
    responseDataGetter: getResponseDataVOld,
  });
};

const doRequestNamesChange = (payload) => {
  return toastHandler(axios.post('/user/profile/names/change', payload), true);
};

export {
  doGetProfileDetails,
  doUpdateEmail,
  doGetUserPaymentMethod,
  doUpdateBillingAddress,
  doDeletePaymentMethod,
  doPostPaymentMethod,
  doGetPaymentHistory,
  doGetPaymentReceiptPdf,
  doUpdateProfileNames,
  doRequestNamesChange,
};
