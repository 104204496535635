import '@/styles/vuetify-global-settings.scss';

import { createVuetify } from 'vuetify';

import { aliases } from './vuetifyIconSet.ts';

const lightTheme = {
  dark: false,
  colors: {
    primary: '#263684',
    'primary-surface': '#EDEDF7',
    'primary-border': '#AEBDDB',
    'primary-focus': '#C2C6D9',
    'primary-pressed': '#161F4B',
    secondary: '#76AAFF',
    'secondary-surface': '#F0F3FF',
    'secondary-hover': '#6B92D2',
    'secondary-border': '#B8D2FF',
    'secondary-pressed': '#475F85',
    accent: '#FFAB18',
    'accent-surface': '#FFFCF0',
    'accent-border': '#FFD68F',
    error: '#EB5953',
    'error-surface': '#FEF7F6',
    'error-border': '#F5B0AD',
    'error-hover': '#D93730', // Kept for backwards compatibility
    'error-new-hover': '#F7EBEC',
    warning: '#EA780E',
    'warning-surface': '#FFF8F2',
    'warning-border': '#FFB470',
    info: '#0B5CD7',
    'info-border': '#B6CFF3',
    success: '#13B577',
    'success-surface': '#F6FEFB',
    'success-border': '#8FD6BB',
    background: '#F0F0F3',
    card: '#F7F7F8',
    grey: '#FFFFFF',
    'grey-lighten-6': '#B9B9B9',
    'grey-lighten-5': '#F5F5F5',
    'grey-lighten-4': '#EDEDED',
    'grey-lighten-3': '#E0E0E0',
    'grey-lighten-2': '#C2C2C2',
    'grey-lighten-1': '#9E9E9E',
    'grey-darken-1': '#757575',
    'grey-darken-2': '#616161',
    'grey-darken-3': '#424242',
    'grey-darken-4': '#0A0A0A',
    nightgradient: '#263684',
    'nightgradient-darken4': '#060F3D',
    coldgradient: '#8BB8FF',
    'coldgradient-darken4': '#4C70DF',
    hotgradient: '#FFB018',
    'hotgradient-darken4': '#FF8718',
    'hotgradient-darken3': '#FD6B51',
  },
  // I don't know why but setting CSS variables here doesn't work
  // https://vuetifyjs.com/en/features/theme/#theme-configuration
  variables: {},
};

const darkTheme = {
  dark: true,
  colors: {
    primary: '#263684',
    'primary-surface': '#EDEDF7',
    'primary-border': '#AEBDDB',
    'primary-focus': '#C2C6D9',
    'primary-pressed': '#161F4B',
    secondary: '#76AAFF',
    'secondary-surface': '#F0F3FF',
    'secondary-hover': '#6B92D2',
    'secondary-border': '#B8D2FF',
    'secondary-pressed': '#475F85',
    accent: '#FFAB18',
    'accent-surface': '#FFFCF0',
    'accent-border': '#FFD68F',
    error: '#EB5953',
    'error-surface': '#FEF7F6',
    'error-border': '#F5B0AD',
    'error-hover': '#D93730', // Kept for backwards compatibility
    'error-new-hover': '#F7EBEC',
    warning: '#EA780E',
    'warning-surface': '#FFF8F2',
    'warning-border': '#FFB470',
    info: '#0B5CD7',
    'info-border': '#B6CFF3',
    success: '#13B577',
    'success-surface': '#F6FEFB',
    'success-border': '#8FD6BB',
    background: '#F0F0F3',
    card: '#F7F7F8',
    grey: '#FFFFFF',
    'grey-lighten-6': '#B9B9B9',
    'grey-lighten-5': '#F5F5F5',
    'grey-lighten-4': '#EDEDED',
    'grey-lighten-3': '#E0E0E0',
    'grey-lighten-2': '#C2C2C2',
    'grey-lighten-1': '#9E9E9E',
    'grey-darken-1': '#757575',
    'grey-darken-2': '#616161',
    'grey-darken-3': '#424242',
    'grey-darken-4': '#0A0A0A',
    nightgradient: '#263684',
    'nightgradient-darken4': '#060F3D',
    coldgradient: '#8BB8FF',
    'coldgradient-darken4': '#4C70DF',
    hotgradient: '#FFB018',
    'hotgradient-darken4': '#FF8718',
    'hotgradient-darken3': '#FD6B51',
  },
  variables: {},
};

// https://vuetifyjs.com/en/features/global-configuration/
export default createVuetify({
  icons: {
    aliases,
  },
  theme: {
    defaultTheme: 'lightTheme',
    themes: { lightTheme, darkTheme },
    options: { customProperties: true },
  },
  // You can change the default props or styles here
  defaults: {
    VCardActions: {
      style: 'min-height: auto;',
    },
    VLabel: {
      style: 'opacity: 1;',
    },
    VRadio: {
      // I don't want to add `display: flex` to VRadio as it would be very intrusive
      VLabel: {
        style: 'flex: 1 1 0; opacity: 1;',
      },
    },
  },
});
