import axios from 'axios';

import { getResponseDataNotWrapped, getResponseDataVOld, responseHandler } from '@/api/utilities.ts';

const doGetUserSubscription = () => {
  return responseHandler(axios.get('/subscription/user'), {
    responseDataGetter: getResponseDataVOld,
    customErrorMessage: 'An error occurred while fetching your subscription details. Please contact support.',
  });
};

const doGetPlans = () => {
  return responseHandler(axios.get('/subscription/plans'));
};

const doGetPaymentIntent = () => {
  return axios.get('/subscription/payment-intent');
};

const doPostConfirmSubscription = (payload) => {
  return responseHandler(axios.post('/subscription/confirm-payment', payload), {
    hasToShowToasts: false,
    responseDataGetter: getResponseDataVOld,
  });
};

const doPostConfirmSingleCharge = (payload) => {
  return responseHandler(axios.post('/single-charge/confirm-payment', payload), {
    hasToShowToasts: false,
  });
};

const doGetIfLifetimeIsActive = () => {
  return responseHandler(axios.get('/single-charge/plans/lifetime'));
};

const doPostConfirmDowngradeSubscription = (payload) => {
  return axios.post('/subscription/downgrade', payload);
};

const doGetSubscriptionChangePreview = (payload) => {
  return axios.get('/subscription/change/preview', payload);
};

const doPostConfirmFreeSubscription = (payload) => {
  return responseHandler(axios.post('/subscription/free', payload), {
    responseDataGetter: getResponseDataNotWrapped,
    showOnlyErrorMessagesAsToast: false,
  });
};

const doGetDiscountByPromoCode = (payload) => {
  return responseHandler(axios.get('/subscription/promo/check', payload), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

const doGetReceipt = (payload) => {
  return responseHandler(axios.get('/subscription/receipt', payload), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

const doGetLifetimeReceiptDetails = (payload) => {
  return responseHandler(axios.post('/user/billing/lifetime/receipt/pdf', payload), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

export {
  doGetUserSubscription,
  doGetPlans,
  doGetPaymentIntent,
  doPostConfirmSubscription,
  doGetIfLifetimeIsActive,
  doPostConfirmSingleCharge,
  doPostConfirmDowngradeSubscription,
  doGetSubscriptionChangePreview,
  doPostConfirmFreeSubscription,
  doGetDiscountByPromoCode,
  doGetReceipt,
  doGetLifetimeReceiptDetails,
};
