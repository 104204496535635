import axios from 'axios';

import { getResponseDataNotWrapped, responseHandler, toastHandler } from '@/api/utilities.ts';

/**
 * @deprecated Use getArticle from '@/api/article-v2' instead
 */
const doGetArticle = ({ id, slug, topicSlug, stateSlug }) => {
  return responseHandler(
    axios.get('/articles/view-article-details', {
      params: {
        id,
        slug,
        topicSlug,
        stateSlug,
      },
    }),
    {
      responseDataGetter: getResponseDataNotWrapped,
    },
  );
};

const doPostUpVoteArticle = (articleId) => {
  return axios
    .post('/articles/up-vote', { id: articleId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('There was an error!', error);
      return error;
    });
};

const doPostDownVoteArticle = (articleId) => {
  return axios
    .post('/articles/down-vote', { id: articleId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('There was an error!', error);
      return error;
    });
};

const doGetArticleQuiz = (payload) => {
  if (payload.params.slug) {
    return toastHandler(axios.get(`/article/${payload.params.slug}/quiz`, payload), false, true);
  } else {
    return toastHandler(axios.get('/article/quiz', payload), false, true);
  }
};

const doPostQuizAnswer = (payload) => {
  return toastHandler(axios.post('/article/quiz/question/answer', payload), false, true);
};

const doGetQuizResults = (params) => {
  return responseHandler(axios.get('/article/quiz/results', { params: params }), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

const doUpdateQuizStatus = (payload) => {
  return toastHandler(axios.put('/article/quiz/user/status', payload), false);
};

const doPostPretest = (payload) => {
  return toastHandler(axios.post('/article/pretest', payload), false, true);
};

const doUpdateShowPretestOnboarding = (payload) => {
  return toastHandler(axios.put('/professional/status', payload), false, true);
};

const doSaveQuizUserStarted = (payload) => {
  return toastHandler(axios.post('/article/quiz/start', payload), false, true);
};

const doSaveQuizUserFinished = (payload) => {
  return toastHandler(axios.put('/article/quiz/finish', payload), false, true);
};

const doSavePretestStarted = (payload) => {
  return toastHandler(axios.post('/article/pretest/start', payload), false, true);
};

const doGetEvaluation = (articleId) => {
  return responseHandler(axios.get(`/article/${articleId}/activity/evaluation`), {
    responseDataGetter: getResponseDataNotWrapped,
    hasToShowToasts: false,
  });
};

const doSaveEvaluation = ({ params }) => {
  return responseHandler(axios.post(`/article/${params.id}/activity/evaluation/answer`, params), {
    responseDataGetter: getResponseDataNotWrapped,
    hasToShowSuccessToasts: false,
  });
};

const doAgreeActivityDisclosure = (articleId) => {
  return responseHandler(
    axios.post(`/article/${articleId}/activity/disclosure/agree`, {
      params: { id: articleId, hashId: localStorage.getItem('acapediaGuestHashSession') },
    }),
    {
      responseDataGetter: getResponseDataNotWrapped,
    },
  );
};

const doCheckIfActivityDisclosureShouldBeShown = (articleId) => {
  return responseHandler(axios.get(`/article/${articleId}/activity/disclosure/check`, { params: { id: articleId } }), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

const doGetMostRecentActiveActivityByArticleId = (articleId) => {
  return responseHandler(axios.get(`/article/${articleId}/activity`, { params: { articleId: articleId } }), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};
export {
  doGetArticle,
  doGetArticleQuiz,
  doPostUpVoteArticle,
  doPostDownVoteArticle,
  doPostQuizAnswer,
  doGetQuizResults,
  doUpdateQuizStatus,
  doPostPretest,
  doUpdateShowPretestOnboarding,
  doSaveQuizUserStarted,
  doSaveQuizUserFinished,
  doSavePretestStarted,
  doGetEvaluation,
  doSaveEvaluation,
  doAgreeActivityDisclosure,
  doCheckIfActivityDisclosureShouldBeShown,
  doGetMostRecentActiveActivityByArticleId,
};
