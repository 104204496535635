import { createApp, Transition, TransitionGroup } from 'vue';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import VueGtag from 'vue-gtag';

// * ToastPlugin must be imported before our styles
import { ToastPlugin } from '@/lib/toast/toast.plugin.ts';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router/router';
// Keep this after the import of vuetify
import './style.css';

import BreakpointPlugin from '@/plugins/breakpoint.plugin';
import { initSentry } from '@/plugins/sentry';
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query';
import { createHead, VueHeadMixin } from '@unhead/vue';
import UniversalSocialauth from 'universal-social-auth';
import RouterPrefetch from 'vue-router-prefetch';

import { useAuthStore } from '@/store-v2/auth.store';

import { getFeatureFlagProvider } from '@/lib/featureFlag';
// TODO: VUE 3 migration - dependencies
import { setUpApiInterceptors } from '@/api/interceptors.ts';

import { pinia } from './store-v2/store.ts';

const app = createApp(App);

// Enable devtools in non-production environments
if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
  app.config.devtools = true;
}

const socialAuthOptions = {
  providers: {
    google: {
      clientId: import.meta.env.VITE_SOCIALITE_GOOGLE_CLIENT_ID,
      redirectUri: import.meta.env.VITE_SOCIALITE_GOOGLE_REDIRECT_URL || `${window.location.origin}/callback`,
    },
  },
};

const socialAuth = new UniversalSocialauth(axios, socialAuthOptions);
app.config.globalProperties.$Oauth = socialAuth;

app.use(vuetify);

app.use(ToastPlugin);

app.use(VueGtag, {
  config: {
    id: import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID,
    params: {
      send_page_view: false,
    },
  },
});

const head = createHead();
app.use(head);

// The only allowed mixin until we migrate all the components to composition API
app.mixin(VueHeadMixin);

// Set up API interceptors
setUpApiInterceptors();

initSentry({ app, router, env: import.meta.env });

// Use Breakpoint Plugin
app.use(BreakpointPlugin);

// Vue Query setup
const myClient = new QueryClient();
app.use(VueQueryPlugin, { queryClient: myClient });

app.use(pinia);

// Do not remove these lines
// This resolves some breaking change in the vue3-treeselect package due that in vue 2 were done some hot resolve dependencies
// in vue 3 is no longer in this lib so we need to register the native component before resolving
// More details in: https://vuejs.org/guide/extras/render-function.html#built-in-components
app.component('Transition', Transition);
app.component('TransitionGroup', TransitionGroup);

// Initialize Feature Flags
const featureFlagProvider = new getFeatureFlagProvider();
if (!featureFlagProvider) {
  console.error('Failed to initialize the feature flag provider');
  Sentry.captureMessage('Failed to initialize the feature flag provider');
  initVue();
} else {
  featureFlagProvider
    .init()
    .then(() => {
      console.log('Feature flag provider initialized successfully');
    })
    .catch((error) => {
      console.error('Failed to initialize the feature flag provider -', error);
    })
    .finally(() => {
      initVue();
    });
}

// Function to load initial data (from auth store)
const loadInitialData = async () => {
  try {
    await useAuthStore().bootstrapUserData();
  } catch (error) {
    console.error('Failed to load initial data', error);
    Sentry.captureException(error);
  }
};

const initVue = async () => {
  // NOW: reviser order of init vue
  await loadInitialData();
  app.use(router);
  app.use(RouterPrefetch, {
    componentName: 'RouterLinkPrefetch',
  });
  app.mount('#app');
};
