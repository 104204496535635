import { storeToRefs } from 'pinia';

import { useAuthStore } from '@/store-v2/auth.store';

/**
 * Handles the authentication guard for routes that require authentication.
 *
 * @param {Object} to - The target route object.
 * @param {Function} next - The navigation guard function to proceed to the next route.
 */
export function enforceAuthGuard(to, next) {
  const targetRouteRequiresAuth = to.meta.requiresAuth;

  if (!targetRouteRequiresAuth) {
    next();
    return;
  }

  const { isLoggedInUser } = storeToRefs(useAuthStore());
  if (isLoggedInUser.value) {
    next();
    return;
  }

  redirectGuestToLogin(to, next);
}

/**
 * Redirects unauthenticated users to the login page.
 *
 * @param {Object} to - The target route object.
 * @param {Function} next - The navigation guard function to proceed to the next route.
 */
function redirectGuestToLogin(to, next) {
  const nextRoute = to.name;
  const nextParams = Object.keys(to.params).length > 0 ? JSON.stringify(to.params) : null;
  const nextQuery = Object.keys(to.query).length > 0 ? JSON.stringify(to.query) : null;
  const redirectParams = { nextRoute, ...(nextParams && { nextParams }), ...(nextQuery && { nextQuery }) };
  console.warn(`User attempted to access the route "${to.path}" without being authenticated.`);
  redirectToLogin(next, redirectParams);
}

/**
 * Redirects to the login page.
 *
 * @param {Function} next - The resolver function for the navigation hook. Must be called to proceed with navigation.
 * @param {Object} routeParams - Parameters to be passed to the login page by query.
 */
const redirectToLogin = (next, routeParams) => next({ name: 'login', query: routeParams });
