/* eslint-disable @typescript-eslint/no-explicit-any */

import { createRouter, createWebHistory } from 'vue-router';
import { enforceAuthGuard } from '@/router/authGuard.js';
import { initializeSessionGuard } from '@/router/initializeSessionGuard';
import {
  ensureActiveSubscriptionGuard,
  ensureUserIsGuest,
  ensureValidPasswordResetToken,
} from '@/router/routerGuards.js';

import { useArticlesStore } from '@/store-v2/articles.store.js';
import { useFeedStore } from '@/store-v2/feed.store';

const routes = [
  {
    // If you intend to update this route, kindly ensure that the route in the backend sitemap generator is also updated.
    // The sitemap generator can be found in the acapedia-submitters repository,
    // under the path /app/Console/Commands/GenerateProfessionalsSitemap.php
    // Please conduct a comprehensive search of the string "/" in the acapedia-submitters repository,
    // as this route is used in multiple locations.
    path: '/',
    name: 'home',
    component: () => import('@/pages/HomePage.vue'),
    meta: {
      title: 'Earn CME and MOC Credit by Reading Trending Medical Research Articles | Acapedia',
      description:
        'Stay up-to-date with the latest medical research in your field and earn CME and MOC along the way. ' +
        'Acapedia offers a wide selection of new and interesting articles, and provides MOC credit ' +
        'for all major medical boards including ABIM, ABS, ABP, ABA and others. Start earning CME today!',
      requiresAuth: false,
    },
    children: [
      {
        path: 'topics/:topicSlug/states',
        name: 'main-feed-topic-states',
        component: () => import('@/pages/SpecialRequirements/HomeTopicStatesDialogPage.vue'),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  {
    // In case you want to update this route, please update the route in the backend sitemap generator as well
    // The sitemap generator is a command located in the acapedia-submitters repository
    // in the path /app/Console/Commands/GenerateProfessionalsSitemap.php
    path: '/article/:slug',
    name: 'article',
    component: () => import('@/pages/Article/Abstract/ArticleAbstractPage.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/article/:id/pdf',
    name: 'article-file-view',
    component: () => import('@/pages/Article/ArticlePdfPage.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      const hasToShowPretest = await useFeedStore().checkIfHasToShowPretest({ articleId: to.params.id });
      if (hasToShowPretest) {
        next({ name: 'article-pretest-onboarding', params: { articleId: to.params.id?.toString() } });
      } else {
        return next();
      }
    },
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/special-requirements/topics/:topicSlug/states/:stateSlug/cme-experiences/articles/:slug/abstract/pdf',
    name: 'special-requirements-article-pdf',
    component: () => import('@/pages/SpecialRequirements/SRArticlePdfPage.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      const hasToShowPretest = await useFeedStore().checkIfHasToShowPretest({ articleSlug: to.params.slug });
      if (hasToShowPretest) {
        next({
          name: 'special-requirements-article-pretest-onboarding',
          params: {
            articleSlug: to.params.slug,
            topicSlug: to.params.topicSlug,
            stateSlug: to.params.stateSlug,
          },
        });
      } else {
        return next();
      }
    },
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/article/:id/disclosure/pdf',
    name: 'article-disclosure-pdf',
    component: () => import('@/pages/Article/ArticleDisclosurePDFPage.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    // If you intend to update this route, kindly ensure that the route in the backend sitemap generator is also updated.
    // The sitemap generator can be found in the acapedia-submitters repository,
    // under the path /app/Console/Commands/GenerateProfessionalsSitemap.php
    // Please conduct a comprehensive search of the string "/login" in the acapedia-submitters repository,
    // as this route is used in multiple locations.
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login/LoginPage.vue'),
    meta: {
      title: 'Log into your account to earn CME and MOC credits | Acapedia',
      description: 'ABIM, ABS, ABP and ABA diplomates: Log into Acapedia now to earn CME and MOC credits',
      requiresAuth: false,
    },
    beforeEnter: initializeSessionGuard,
  },
  // This route is created for emails to increase the abstraction level of the path
  {
    path: '/login/fluorosafety',
    name: 'login-fluorosafety',
    redirect: function (to: any) {
      const token = to.query.token;

      return { name: 'login', query: { nextRoute: 'premium-courses-feed', token } };
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/pages/Signup/SignupPage.vue'),
    meta: {
      title: 'Register with Acapedia today to start earning CME and MOC credits',
      description:
        'Stay up to date with Acapedia on all the latest trends in medical innovation. ' +
        'Register now to being earning CME and MOC credits!',

      requiresAuth: false,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/pages/Profile/ProfilePage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Edit Your Profile Information | Acapedia',
      description:
        'Keep your profile information up-to-date on Acapedia to ensure that you receive credit for your continuing education activities. Edit your name, phone number, email, contact information, and medical board information easily from your account. Sign in to your account and make the necessary changes to your profile today. Stay updated and earn credits towards your continuing education requirements.',
    },
  },

  {
    path: '/profile/edit',
    name: 'profile-edit',
    component: () => import('@/pages/Profile/ProfileEditPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/password-reset/:token(.*)',
    name: 'password-reset',
    beforeEnter: ensureValidPasswordResetToken,
    component: () => import('@/pages/Password/PasswordResetPage.vue'),
    meta: {
      requiresAuth: false,
      nextRoute: 'home',
    },
  },
  {
    // This path is used by an email do not change it without changing the email template
    path: '/password-create/fluorosafety/:email/:token(.*)',
    name: 'password-create-fluorosafety',
    beforeEnter: ensureValidPasswordResetToken,
    component: () => import('@/pages/Password/PasswordCreateFluoroSafetyPage.vue'),
    meta: {
      requiresAuth: false,
      nextRoute: 'premium-courses-feed',
    },
  },
  {
    path: '/article/:id/quiz',
    name: 'article-quiz',
    component: () => import('@/pages/Article/ArticleQuizPage.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/article/:id/quiz/results',
    name: 'article-quiz-results',
    component: () => import('@/pages/Article/ArticleQuizResultsPage.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/article/:id/quiz/results/details',
    name: 'article-quiz-results-details',
    component: () => import('@/pages/Article/ArticleQuizResultsDetailsPage.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/transcripts',
    name: 'transcripts',
    component: () => import('@/pages/TranscriptsPage.vue'),
    meta: {
      title: 'View Your CME Transcript | Acapedia',
      description: `Keep track of your CME activities and progress on Acapedia's CME transcript page. Easily view the CME courses you have completed and the credits you have earned. Log in to your account and access your transcript today to stay organized and meet your continuing education requirements. Try it now!`,
      requiresAuth: true,
    },
  },
  {
    path: '/transcripts/:id/details',
    name: 'credit-details',
    component: () => import('@/pages/Transcript/TranscriptDetailsPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/my-plan',
    name: 'my-plan',
    component: () => import('@/pages/MyPlanPage.vue'),
    meta: {
      title: 'Your CME and MOC Plan | Acapedia',
      description:
        'Sign up for an account on Acapedia and select the CME plan that fits your needs. Our website offers a wide selection of new and interesting articles, as well as premium high-quality content with animations and videos. Choose your plan and start earning credits towards your continuing education requirements!',
      requiresAuth: true,
    },
  },
  {
    path: '/my-plan/change',
    name: 'change-plan',
    component: () => import('@/pages/ChangePlanPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    // This path is created for emails to increase the abstraction level of the path
    path: '/my-plan/downgrade',
    redirect: {
      name: 'change-plan',
    },
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/pages/PricingPage.vue'),
    meta: {
      title: 'Pricing',
      description: 'Pricing page',
      requiresAuth: false,
    },
    beforeEnter: ensureUserIsGuest,
  },
  {
    path: '/checkout/:selectedPlan?',
    name: 'checkout',
    component: () => import('@/pages/Checkout/CheckoutPage.vue'),
    meta: {
      requiresAuth: true,
      // We need to keep alive this page, so in case of failure,
      // the user can retry without having to start over.
      keepAlive: true,
    },
  },
  {
    path: '/plan/pro/payment-success',
    name: 'payment-success-pro',
    component: () => import('@/pages/Checkout/PaymentSuccessProPage.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      if (from.name !== 'checkout') next({ name: 'billing', replace: true });

      next();
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/plan/pro/billing-update-success',
    name: 'upsell-billing-update-success',
    component: () => import('@/pages/Checkout/UpsellBillingUpdateSuccessPage.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      if (from.name !== 'checkout') next({ name: 'my-plan', replace: true });

      next();
    },
    meta: {
      requiresAuth: true,
      usesNewLayout: true,
    },
  },
  {
    path: '/plan/lifetime/payment-success',
    name: 'payment-success-lifetime',
    component: () => import('@/pages/Checkout/PaymentSuccessLifetimePage.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      if (from.name !== 'checkout') next({ name: 'billing', replace: true });

      next();
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/payment-failure',
    name: 'payment-failure',
    component: () => import('@/pages/Checkout/PaymentFailurePage.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      if (from.name !== 'checkout') next({ name: 'billing', replace: true });

      next();
    },
    meta: {
      requiresAuth: true,
      // We need to keep alive this page, so in case of failure,
      // the user can return to the checkout without having to start over.
      keepAlive: true,
    },
  },
  {
    path: '/email-cta/upgrade/pro',
    name: 'email-cta-upgrade-pro',
    redirect: {
      name: 'checkout',
      params: { selectedPlan: 'pro' },
    },
  },
  {
    path: '/email-cta/upgrade/lifetime',
    name: 'email-cta-upgrade-lifetime',
    redirect: {
      name: 'checkout',
      params: { selectedPlan: 'lifetime' },
    },
  },
  {
    path: '/billing',
    name: 'billing',
    component: () => import('@/pages/Billing/BillingPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/billing/update',
    name: 'update-billing',
    component: () => import('@/pages/Billing/BillingUpdatePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/billing/lifetime-plan-receipt/:receiptId/pdf',
    name: 'billing-lifetime-receipt',
    component: () => import('@/pages/LifetimeReceiptPage.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/article/:articleId/pretest',
    name: 'article-pretest',
    component: () => import('@/pages/Pretest/PretestPage.vue'),
    props: true,
    beforeEnter: (to: any, from: any, next: any) => {
      if (to.name === 'article-pretest' && from.name !== 'article-pretest-onboarding') {
        return next({ name: 'article-pretest-onboarding', params: { articleId: to.params.articleId?.toString() } });
      } else {
        next();
      }
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'onboarding',
        name: 'article-pretest-onboarding',
        component: () => import('@/pages/Pretest/ArticlePretestOnboardingPage.vue'),
        props: true,
        beforeEnter: async (to: any, from: any, next: any) => {
          const hasToShowPretest = await useFeedStore().checkIfHasToShowPretest({ articleId: to.params.articleId });

          if (hasToShowPretest) {
            next();
          } else {
            console.warn('Route access to article-pretest-onboarding denied');
            return next({ name: 'home' });
          }
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'results',
        name: 'article-pretest-results',
        component: () => import('@/pages/Pretest/ArticlePretestResultsPage.vue'),
        props: true,
        beforeEnter: (to: any, from: any, next: any) => {
          if (from.name === 'article-pretest') {
            next();
          } else {
            console.warn('Route access to article-pretest-results denied');
            return next({ name: 'home' });
          }
        },
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/special-requirements/topics/:topicSlug/states/:stateSlug/article/:articleSlug/pretest',
    name: 'special-requirements-article-pretest',
    component: () => import('@/pages/Pretest/PretestPage.vue'),
    props: true,
    beforeEnter: (to: any, from: any, next: any) => {
      if (
        to.name === 'special-requirements-article-pretest' &&
        from.name !== 'special-requirements-article-pretest-onboarding'
      ) {
        return next({
          name: 'special-requirements-article-pretest-onboarding',
          params: {
            articleSlug: to.params.articleSlug,
            topicSlug: to.params.topicSlug,
            stateSlug: to.params.stateSlug,
          },
        });
      } else {
        next();
      }
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'onboarding',
        name: 'special-requirements-article-pretest-onboarding',
        component: () => import('@/pages/Pretest/SRArticlePretestOnboardingPage.vue'),
        props: true,
        beforeEnter: async (to: any, from: any, next: any) => {
          const hasToShowPretest = await useFeedStore().checkIfHasToShowPretest({ articleSlug: to.params.articleSlug });

          if (hasToShowPretest) {
            next();
          } else {
            console.warn('Route access to special-requirements-article-pretest-onboarding denied');
            return next({ name: 'home' });
          }
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'results',
        name: 'special-requirements-article-pretest-results',
        component: () => import('@/pages/Pretest/SRArticlePretestResultsPage.vue'),
        props: true,
        beforeEnter: (to: any, from: any, next: any) => {
          if (from.name === 'special-requirements-article-pretest') {
            next();
          } else {
            console.warn('Route access to special-requirements-article-pretest-results denied');
            return next({ name: 'home' });
          }
        },
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/referrals',
    name: 'referrals',
    component: () => import('@/pages/Referrals/ReferralsPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/pages/Legal/PrivacyPage.vue'),
    meta: {
      title: 'Acapedia Privacy Policy | Protecting Physicians’ Data & Information',
      description:
        'Explore Acapedia’s Privacy Policy to learn how we safeguard your personal data and information while providing high-quality CME resources for physicians and medical professionals.',
      requiresAuth: false,
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/pages/Legal/TermsOfUsePage.vue'),
    meta: {
      title: 'Acapedia Terms of Use | CME Education for Physicians & Medical Professionals',
      description:
        'Review Acapedia’s Terms of Use for our top-rated CME educational materials, designed to empower physicians and medical professionals. Learn confidently with Acapedia’s trusted resources.',
      requiresAuth: false,
    },
  },
  {
    // If you intend to update this route, kindly ensure that the route in the backend sitemap generator is also updated.
    // The sitemap generator can be found in the acapedia-submitters repository,
    // under the path /app/Console/Commands/GenerateProfessionalsSitemap.php
    // Please conduct a comprehensive search of the string "/premium-courses" in the acapedia-submitters repository,
    // as this route is used in multiple locations.
    path: '/premium-courses',
    name: 'premium-courses-feed',
    component: () => import('@/pages/Course/Feed/PremiumCoursesFeedPage.vue'),
    props: true,
    meta: {
      title: 'Premium CME and MOC Content for physicians to meet state requirements | Acapedia',
      description:
        'Acapedia offers premium high-quality CME and MOC content designed to meet state and regulatory ' +
        'requirements. Our engaging animations and videos make learning enjoyable and effective. Upgrade to ' +
        'premium today and earn the credits you need to advance your career. Start now!',
      requiresAuth: false,
    },
  },
  {
    // If you intend to update this route, kindly ensure that the route in the backend sitemap generator is also updated.
    // The sitemap generator can be found in the acapedia-submitters repository,
    // under the path /app/Console/Commands/GenerateProfessionalsSitemap.php
    // Please conduct a comprehensive search of the string "/abstract" in the acapedia-submitters repository,
    // as this route is used in multiple locations.
    path: '/premium-courses/:courseSlug/abstract',
    name: 'course-abstract',
    component: () => import('@/pages/Course/CourseAbstractPage.vue'),
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/premium-courses/:courseId/enrollments/:enrollmentId?/overview/',
    name: 'course-enrollment',
    component: () => import('@/pages/Course/Overview/CourseOverviewPage.vue'),
    props: true,
    beforeEnter: ensureActiveSubscriptionGuard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/special-requirements/topics/:topicSlug/states/:stateSlug/premium-courses/:courseId/enrollments/:enrollmentId?/overview',
    name: 'special-requirements-course-enrollment',
    redirect: { name: 'course-enrollment' },
  },
  {
    path: '/premium-courses/:courseId/enrollments/:enrollmentId?/module/:moduleId/quiz',
    name: 'course-module-quiz',
    component: () => import('@/pages/Course/Overview/CourseModuleQuizPage.vue'),
    props: true,
    beforeEnter: ensureActiveSubscriptionGuard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/special-requirements/topics/:topicSlug/states/:stateSlug/cme-experiences/courses/:courseId/enrollments/:enrollmentId?/module/:moduleId/quiz',
    name: 'special-requirements-course-module-quiz',
    redirect: { name: 'course-module-quiz' },
  },
  {
    path: '/premium-courses/:courseId/enrollments/:enrollmentId?/pretest',
    name: 'course-pretest',
    component: () => import('@/pages/Course/Pretest/CoursePretestPage.vue'),
    props: true,
    beforeEnter: ensureActiveSubscriptionGuard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/special-requirements/topics/:topicSlug/states/:stateSlug/cme-experiences/courses/:courseId/enrollments/:enrollmentId?/pretest',
    name: 'special-requirements-course-pretest',
    redirect: { name: 'course-pretest' },
  },
  {
    path: '/premium-courses/:courseId/disclosure/pdf',
    name: 'course-disclosure-pdf',
    component: () => import('@/pages/Course/CourseDisclosurePdfPage.vue'),
    props: true,
    beforeEnter: ensureActiveSubscriptionGuard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/premium-courses/:courseId/module/:moduleId/content',
    name: 'course-module-content',
    component: () => import('@/pages/Course/Overview/CourseModuleContentPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/special-requirements/topics/:topicSlug/states/:stateSlug/cme-experiences/courses/:courseId/module/:moduleId/content',
    name: 'special-requirements-course-module-content',
    redirect: { name: 'course-module-content' },
  },
  {
    path: '/premium-courses/module/content-preview',
    name: 'course-module-content-preview',
    component: () => import('@/pages/Course/Overview/CourseModuleContentPreviewPage.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/special-requirements',
    name: 'special-requirements',
    component: () => import('@/pages/SpecialRequirements/SpecialRequirementsPage.vue'),
    meta: {
      requiresAuth: false,
    },
    props: true,
    children: [
      {
        path: 'topics/:topicSlug/states',
        name: 'special-requirements-topic-states',
        component: () => import('@/pages/SpecialRequirements/TopicStatesPage.vue'),
        meta: {
          type: 'special-requirements',

          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/special-requirements/topics/:topicSlug/states/:stateSlug/feed',
    name: 'special-requirements-topic-state-feed',
    component: () => import('@/pages/SpecialRequirements/TopicStateFeedPage.vue'),
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/special-requirements/topics/:topicSlug/states/:stateSlug/cme-experiences/articles/:slug/abstract',
    name: 'special-requirements-article-abstract',
    component: () => import('@/pages/SpecialRequirements/SpecialRequirementsArticleAbstractPage.vue'),
    props: true,
    meta: {
      type: 'article',
      requiresAuth: false,
    },
    children: [
      {
        path: 'states',
        name: 'special-requirements-topic-states-article-abstract',
        meta: { type: 'article' },
        component: () => import('@/pages/SpecialRequirements/TopicStatesAbstractPage.vue'),
      },
    ],
  },
  {
    path: '/special-requirements/topics/:topicSlug/states/:stateSlug/cme-experiences/courses/:courseSlug/abstract',
    name: 'special-requirements-course-abstract',
    redirect: { name: 'course-abstract' },
  },
  {
    path: '/special-requirements/topics/:topicSlug/states/:stateSlug/cme-experiences/articles/:slug/quiz/:id',
    name: 'special-requirements-article-quiz',
    component: () => import('@/pages/SpecialRequirements/SpecialRequirementsArticleQuizPage.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/callback',
    name: 'socialCallback',
    component: {
      template: '<div class="auth-component"></div>',
    },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/pages/ErrorPage.vue'),
    props: true,
    meta: {
      requiresAuth: false,
    },
    beforeEnter: function (to: any, from: any, next: any) {
      if (to.params.statusCode === 500) return next({ name: 'error-500' });

      return next();
    },
  },
  // TODO: to be removed, only for testing purposes while we set up Sentry
  {
    path: '/sentry-test',
    name: 'sentry-test',
    component: () => import('@/pages/SentryTestPage.vue'),
  },
  {
    path: '/error/500',
    name: 'error-500',
    component: () => import('@/pages/ErrorPage.vue'),
    props: {
      statusCode: 500,
      title: 'There is a mistake on our end',
      description:
        'We’ve received an error and are now working on it. Contact us via support to help us resolve this issue faster',
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'error' },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (!savedPosition) {
      return { top: 0 };
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ ...savedPosition, behavior: 'smooth' });
      }, 500);
    });
  },
});

router.beforeResolve((to: any, from: any, next: any) => {
  if (
    ![
      'article',
      'article-file-view',
      'article-disclosure',
      'special-requirements-article-abstract',
      'special-requirements-topic-states-article-abstract',
      'special-requirements-article-pdf',
    ].includes(to.name)
  ) {
    useArticlesStore().setCurrentReadingAtArticleId(null);
  }

  enforceAuthGuard(to, next);
});

export default router;
