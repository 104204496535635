import { vuetifyBreakpointCalculator } from '@/plugins/vuetifyBreakpointCalculator';

/**
 * A Vue.js plugin for managing breakpoints.
 *
 * @deprecated The $breakpoint plugin is deprecated. Please use the useBreakpoints composable instead.
 */
export default {
  install(app) {
    app.mixin({
      computed: {
        $breakpoint() {
          const { width, lgAndUp: isSidebarFixed } = this.$vuetify.display;

          return {
            ...vuetifyBreakpointCalculator(width),
            width,
            isSidebarFixed,
          };
        },
      },
    });
  },
};
