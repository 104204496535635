// https://vuetifyjs.com/en/features/icon-fonts/#creating-a-custom-icon-set

import { h } from 'vue';
import {
  PhCaretDown,
  PhCaretLeft,
  PhCaretLineLeft,
  PhCaretLineRight,
  PhCaretRight,
  PhCheckSquare,
  PhCircle,
  PhRadioButton,
  PhSquare,
} from '@phosphor-icons/vue';
import type { IconAliases } from 'vuetify';

// type IconValue = string | (string | [path: string, opacity: number])[] | JSXComponent;
// type IconValue = VNode<RendererNode, RendererElement, { [key: string]: any }>;

// When declaring as a prop
// declare const IconValue: PropType<IconValue>;
type PhosphorIcon = typeof PhCircle;

type PhosphorIconProps = {
  weight?: 'light' | 'fill';
  size?: number;
};

const BASE_PROPS: PhosphorIconProps = {
  weight: 'light',
  size: 32,
};

const aliases: Partial<IconAliases> = {
  radioOff: buildIcon(PhCircle),
  radioOn: buildIcon(PhRadioButton, { weight: 'fill' }),
  checkboxOff: buildIcon(PhSquare),
  checkboxOn: buildIcon(PhCheckSquare, { weight: 'fill' }),
  prev: buildIcon(PhCaretLeft),
  next: buildIcon(PhCaretRight),
  first: buildIcon(PhCaretLineLeft),
  last: buildIcon(PhCaretLineRight),
  dropdown: buildIcon(PhCaretDown),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function buildIcon(icon: PhosphorIcon, props: PhosphorIconProps = {}): any {
  return h(icon, { ...BASE_PROPS, ...props });
}

export { aliases };
