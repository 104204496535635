import { defineStore } from 'pinia';

import { useEventNotifier } from '@/composables/useEventNotifier.ts';

import {
  doDeletePaymentMethod,
  doGetPaymentHistory,
  doGetPaymentReceiptPdf,
  doGetProfileDetails,
  doGetUserPaymentMethod,
  doPostPaymentMethod,
  doRequestNamesChange,
  doUpdateBillingAddress,
  doUpdateEmail,
  doUpdateProfileNames,
} from '@/api/profile';
import { isSuccessfulResponse } from '@/api/utilities.ts';

const degreesNeedingFullBoardInformation = ['M.D.', 'D.O.', 'M.D., Ph.D.', 'M.D., MPH', 'Unknown'];
const medicalBoardsWithOptionalBoardInformation = ['Self-Report to My Medical Board', 'I do not see my board'];

/**
 * @typedef {Object} Profile
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} degree
 * @property {Object} medicalBoard
 * @property {number} medicalBoard.board_id
 * @property {string} medicalBoard.board_number
 * @property {number} medicalBoard.board_profile_id
 * @property {string} medicalBoard.name
 * @property {string} boardId
 * @property {string} birthday
 * @property {string} dateOfBirthLong
 * @property {string} referralCode
 */

/**
 * @typedef {Object} ProfileState
 * @property {Profile} profile
 * @property {Object | null} paymentMethod
 * @property {boolean} paymentMethodWillExpire
 * @property {boolean} paymentMethodExpired
 * @property {boolean} isLoadingSubmitPayment
 * @property {boolean} isPaymentFieldsUncomplete
 * @property {boolean} stripeFieldsCompleted
 * @property {boolean} hasToShowDefaultPaymentMethod
 * @property {string} msgForAlertExpire
 * @property {boolean} shouldShowCompleteProfileDialog
 */

export const useProfileStore = defineStore('profile', {
  /**
   * @returns {ProfileState}
   */
  state: () => ({
    profile: null,
    paymentMethod: null,
    paymentMethodWillExpire: false,
    paymentMethodExpired: false,
    isLoadingSubmitPayment: false,
    isPaymentFieldsUncomplete: false,
    stripeFieldsCompleted: false,
    hasToShowDefaultPaymentMethod: true,
    msgForAlertExpire: '',
    shouldShowCompleteProfileDialog: false,
    /**
     * Determines if the user should be redirected to the profile page
     * after successfully completing the profile in the CompleteProfileDialog.
     *
     * @type {boolean}
     */
    shouldRedirectToProfileAfterCompletion: false,
  }),
  actions: {
    async getProfile() {
      try {
        const response = await doGetProfileDetails();

        if (isSuccessfulResponse(response)) {
          const { setUser } = useEventNotifier();
          setUser({
            currentPlanName: response.data.currentPlan?.planName ?? 'Free Trial',
          });

          this.setProfile(response.data);
        }
      } catch (error) {
        this.setProfile(null);
      }
    },
    async updateEmail(payload) {
      const response = await doUpdateEmail(payload);

      if (isSuccessfulResponse(response)) {
        // @circular-ignore
        const { useAuthStore } = await import('./auth.store.js');
        await useAuthStore().getUser();
      }

      return response;
    },
    async getUserPaymentMethod() {
      const response = await doGetUserPaymentMethod();

      if (isSuccessfulResponse(response)) {
        this.setPaymentMethod(response.data);

        if (!response.data?.id) {
          this.setHasToShowDefaultPaymentMethod(false);
        }
        return response.data;
      }

      return response.error;
    },
    async updateBillingAddress(payload) {
      const response = await doUpdateBillingAddress(payload);

      if (isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    async deletePaymentMethod() {
      const response = await doDeletePaymentMethod();

      if (isSuccessfulResponse(response)) {
        this.setPaymentMethod(null);
        return response.data;
      }

      return response.error;
    },
    async addPaymentMethod(payload) {
      const response = await doPostPaymentMethod(payload);

      if (isSuccessfulResponse(response)) {
        this.setPaymentMethod(response.data);
        return response.data;
      }

      return response.error;
    },
    async getPaymentHistory() {
      const response = await doGetPaymentHistory();

      if (isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    async getPaymentReceiptPdf(payload) {
      const response = await doGetPaymentReceiptPdf(payload);

      if (isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    async updateProfileNames(payload) {
      const response = await doUpdateProfileNames(payload);

      if (isSuccessfulResponse(response)) {
        await this.getProfile();
        return response;
      }

      return response.error;
    },
    async checkIfPaymentMethodWillExpire() {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      const paymentMethod = await this.getUserPaymentMethod();
      const YEAR_CYCLE = 12;

      if (paymentMethod.card) {
        const expirationMonth = paymentMethod.card.exp_month;
        const expirationYear = paymentMethod.card.exp_year;
        const expirationYearsToCurrent = expirationYear - currentYear;
        const expirationMonthsToCurrent = expirationMonth - currentMonth;

        // works for cases with current date july 2028 with expiration date december 2024
        // works for cases with currrent date january 2025 with expiration date december 2024
        // works for cases with current date August 2024 with expiration date July 2024
        if (currentYear > expirationYear || (currentYear === expirationYear && currentMonth > expirationMonth)) {
          this.setPaymentMethodWillExpire(true);
          this.setPaymentMethodExpired(true);
          this.setMsgForAlertExpire('Your payment method has already expired');
          return;
        }

        // works for cases with current date april 2024 with expiration date december 2024
        // works for cases with current date april 2024 with expiration date may 2024
        // works for cases with current date april 2024 with expiration date april 2024
        if (expirationYearsToCurrent <= 0 && expirationMonthsToCurrent < YEAR_CYCLE) {
          this.setPaymentMethodWillExpire(true);
          this.setMsgForAlertExpire('Your payment method will expire next billing cycle');
          return;
        }

        // works for cases with current date december 2024 with expiration date december 2025
        if (expirationYearsToCurrent <= 1 && expirationMonthsToCurrent <= 0) {
          this.setPaymentMethodWillExpire(true);
          this.setMsgForAlertExpire('Your payment method will expire next billing cycle');
          return;
        }

        this.setPaymentMethodWillExpire(false);
      } else {
        this.setPaymentMethodWillExpire(false);
      }
    },
    async requestNamesChange(payload) {
      const response = await doRequestNamesChange(payload);

      if (isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    unsetProfile() {
      this.setProfile(null);
    },
    /**
     * Open the "Complete Profile" dialog.
     * @param {Object} [options] - Configuration options.
     * @param {boolean} [options.redirectToProfileAfterCompletion=false] - if true, we will redirect to profile
     */
    showCompleteProfileDialog({ redirectToProfileAfterCompletion = false } = {}) {
      this.shouldShowCompleteProfileDialog = true;
      this.shouldRedirectToProfileAfterCompletion = redirectToProfileAfterCompletion;
    },
    /**
     * Shows the complete profile dialog if the user has an incomplete profile.
     *
     * @param {Object} context - The Vuex store context object.
     * @returns {boolean} - Returns `true` if the complete profile dialog should be shown, otherwise `false`.
     */
    showCompleteProfileDialogIfNeeded() {
      if (!this.hasIncompleteProfile) return false;

      this.showCompleteProfileDialog();
      return true;
    },
    closeCompleteProfileDialog() {
      this.shouldShowCompleteProfileDialog = false;
    },
    unsetPaymentWillExpire() {
      this.setPaymentMethodWillExpire(false);
      this.setMsgForAlertExpire('');
    },
    loadingSubmitPayment(payload) {
      this.setIsLoadingSubmitPayment(payload);
    },
    paymentFieldsUncomplete(payload) {
      this.setIsPaymentFieldsUncomplete(payload);
    },
    setShowDefaultPaymentMethod(payload) {
      this.setHasToShowDefaultPaymentMethod(payload);
    },
    setProfile(data) {
      this.profile = data;
    },
    setPaymentMethod(data) {
      this.paymentMethod = data;
    },
    setPaymentMethodWillExpire(data) {
      this.paymentMethodWillExpire = data;
    },
    setPaymentMethodExpired(data) {
      this.paymentMethodExpired = data;
    },
    setIsLoadingSubmitPayment(data) {
      this.isLoadingSubmitPayment = data;
    },
    setIsPaymentFieldsUncomplete(data) {
      this.isPaymentFieldsUncomplete = data;
    },
    setStripeFieldsCompleted(data) {
      this.stripeFieldsCompleted = data;
    },
    setHasToShowDefaultPaymentMethod(data) {
      this.hasToShowDefaultPaymentMethod = data;
    },
    setMsgForAlertExpire(data) {
      this.msgForAlertExpire = data;
    },
  },
  getters: {
    /**
     * @returns {Profile} The profile object from state
     */
    currentProfile: (state) => {
      return state.profile;
    },
    currentPaymentMethod: (state) => {
      return state.paymentMethod;
    },
    hasPaymentMethod() {
      return (
        this.currentPaymentMethod !== null &&
        this.currentPaymentMethod !== undefined &&
        this.currentPaymentMethod.billing_details !== undefined
      );
    },
    willPaymentMethodExpire: (state) => {
      return state.paymentMethodWillExpire;
    },
    isPaymentMethodExpired: (state) => {
      return state.paymentMethodExpired;
    },
    isStripeFieldsCompleted: (state) => {
      return state.stripeFieldsCompleted;
    },
    showDefaultPaymentMethod: (state) => {
      return state.hasToShowDefaultPaymentMethod;
    },
    getMsgForAlertExpire: (state) => {
      return state.msgForAlertExpire;
    },
    hasIncompleteProfile() {
      if (!this.userDegreeNeedsFullBoardInformation) return false;
      if (!this.userMedicalBoardNeedsFullBoardInformation) return false;

      return this.hasIncompleteBoardInformation;
    },
    userDegreeNeedsFullBoardInformation() {
      return degreesNeedingFullBoardInformation.includes(this.currentProfile?.degree);
    },
    userMedicalBoardNeedsFullBoardInformation() {
      return !medicalBoardsWithOptionalBoardInformation.includes(this.currentProfile?.medicalBoard?.name);
    },
    hasIncompleteBoardInformation() {
      return !this.hasBoardId || !this.hasDateOfBirth;
    },
    hasBoardId() {
      return !!this.currentProfile?.medicalBoard?.board_number;
    },
    hasDateOfBirth() {
      const dateOfBirth = this.currentProfile?.dateOfBirth;
      return !!dateOfBirth && dateOfBirth !== 'Unknown';
    },
    academicFullName() {
      return `${this.currentProfile?.firstName} ${this.currentProfile?.lastName}, ${this.currentProfile?.degree}`;
    },
  },
});
