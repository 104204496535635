import axios from 'axios';

import { getResponseDataNotWrapped, getResponseDataVOld, responseHandler, toastHandler } from '@/api/utilities.ts';

const doGetMyFeed = (payload) => {
  return responseHandler(axios.get('/feed', payload), {
    responseDataGetter: getResponseDataVOld,
  });
};

const doGetMostPopular = (payload) => {
  return responseHandler(axios.get('/feed/popular', payload), {
    responseDataGetter: getResponseDataVOld,
  });
};

const doSearchArticlesByText = (payload) => {
  return axios.get('/feed/search', payload);
};

const doPostIncrementShareCounter = (submissionId) => {
  return axios
    .post('/articles/share/count-increment', { id: submissionId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('There was an error!', error);
      return error;
    });
};

const doShowPretestUser = () => {
  return toastHandler(axios.get('/user/pretest/show'), false);
};

const doGetPretestOnboarding = () => {
  return toastHandler(axios.get('/feed/pretest/onboarding'), false);
};

const doShowEvaluation = () => {
  return responseHandler(axios.get('/user/evaluation/show'), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

/**
 * Fetches the single feed.
 * @async
 * @function showSingleFeed
 * @param {Object} options - The options object.
 * @param {string} options.page - The page cursor to fetch.
 * @returns {Promise<Object>} A promise that resolves to the response data.
 */
const showSingleFeed = ({ page }) => {
  // TODO: to be removed when the single feed is ready
  const useSingleFeed = true;

  if (!useSingleFeed) {
    return responseHandler(
      axios.get('/feed/popular', {
        params: {
          cursor: page,
          sort: 'most popular',
        },
      }),
      {
        responseDataGetter: getResponseDataNotWrapped,
      },
    );
  }

  return responseHandler(
    axios.get('/single-feed', {
      params: {
        cursor: page,
      },
    }),
    {
      responseDataGetter: getResponseDataNotWrapped,
    },
  );
};

export {
  doPostIncrementShareCounter,
  doGetMostPopular,
  doGetMyFeed,
  doSearchArticlesByText,
  doShowPretestUser,
  doGetPretestOnboarding,
  doShowEvaluation,
  showSingleFeed,
};
