import { defineStore, storeToRefs } from 'pinia';

import { useAuthStore } from '@/store-v2/auth.store.js';

import {
  doGetDiscountByPromoCode,
  doGetIfLifetimeIsActive,
  doGetLifetimeReceiptDetails,
  doGetPaymentIntent,
  doGetPlans,
  doGetReceipt,
  doGetSubscriptionChangePreview,
  doGetUserSubscription,
  doPostConfirmDowngradeSubscription,
  doPostConfirmFreeSubscription,
  doPostConfirmSingleCharge,
  doPostConfirmSubscription,
} from '@/api/subscriptions';
import { isSuccessfulResponse } from '@/api/utilities.ts';

// We need this to be a function in order to have the feature flags ready
export const getPlanNames = () => {
  const PlanNames = {
    FREE: 'Free',
    PRO: 'Pro',
    LIFETIME: 'Lifetime',
  };

  return PlanNames;
};

const PAST_DUE_STATUS = 'past_due';
const TRIALING_STATUS = 'trialing';

export const useSubscriptionsStore = defineStore('subscriptions', {
  state: () => ({
    userFreePlan: false,
    /** @type {Object | null} */
    userSubscription: null,
    selectedPricingPlanPage: null,
    plans: null,
    couponFormIsOpen: false,
    promoCode: null,
    isLoadingPreview: false,
    isConfirmingPayment: false,
  }),
  actions: {
    setPromoCode(payload) {
      this.promoCode = payload;
    },
    setIsConfirmingPayment(payload) {
      this.isConfirmingPayment = payload;
    },
    toggleCouponForm() {
      this.setCouponFormIsOpen();
    },
    async getUserSubscription() {
      const response = await doGetUserSubscription();
      if (response.data) {
        this.userSubscription = response.data;
      }

      return response;
    },
    removeUserSubscription() {
      this.userSubscription = null;
    },
    async getPlans({ useCache = true } = {}) {
      if (useCache && this.plans) {
        return this.plans;
      }

      this.plans = await doGetPlans();

      return this.plans;
    },
    getPaymentIntent() {
      return doGetPaymentIntent();
    },
    postConfirmSubscription(payload) {
      this.setIsConfirmingPayment(true);
      return doPostConfirmSubscription(payload);
    },
    postConfirmSingleCharge(payload) {
      return doPostConfirmSingleCharge(payload);
    },
    getIfLifetimeIsActive() {
      return doGetIfLifetimeIsActive();
    },
    async postConfirmDowngradeSubscription(payload) {
      const response = await doPostConfirmDowngradeSubscription(payload);

      if (isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    async getSubscriptionChangePreview(payload) {
      if (!this.isLoadingPreview && payload && !this.isConfirmingPayment) {
        this.isLoadingPreview = true;
        const response = await doGetSubscriptionChangePreview({ params: { item: payload, promoCode: this.promoCode } });

        if (isSuccessfulResponse(response)) {
          const data = response.data;
          if (data.totalToPaid !== undefined) {
            this.selectedPricingPlanPage.planOriginalBasePrice = data.planOriginalBasePrice;
            this.selectedPricingPlanPage.upgradeDiscount = data.upgradeDiscount;
            this.selectedPricingPlanPage.couponDiscount = data.couponDiscount;
            this.selectedPricingPlanPage.paidReferralDiscount = data.paidReferralDiscount;
            this.selectedPricingPlanPage.totalToPaid = data.totalToPaid;
            this.selectedPricingPlanPage.discountPerPaidReferral = data.discountPerPaidReferral;
            this.selectedPricingPlanPage.unclaimedPaidReferralCount = data.unclaimedPaidReferralCount;
            this.selectedPricingPlanPage.paidReferralDiscountRemainingAfterPurchase =
              data.paidReferralDiscountRemainingAfterPurchase;
          }
          this.isLoadingPreview = false;
          return data;
        }

        this.isLoadingPreview = false;

        return response.error;
      }
    },
    setIsUserFreePlan(data) {
      this.setUserFreePlan(data);
    },
    postConfirmFreeSubscription(payload) {
      return doPostConfirmFreeSubscription(payload);
    },
    async getDiscountByPromoCode(payload) {
      const response = await doGetDiscountByPromoCode(payload);

      this.promoCode = response.success ? payload.params.promoCode : null;

      return response;
    },
    getReceipt(payload) {
      return doGetReceipt(payload);
    },
    getLifetimeReceiptDetails(payload) {
      return doGetLifetimeReceiptDetails(payload);
    },
    selectPlanForCheckout(payload) {
      this.setSelectedPricingPlanPage(payload);
      const selectedPlanId = payload ? payload.priceHash : null;
      // Every time we trigger a plan selection we should trigger a new preview ready for the checkout
      this.getSubscriptionChangePreview(selectedPlanId);
    },
    setCouponFormIsOpen() {
      this.couponFormIsOpen = !this.couponFormIsOpen;
    },
    setUserFreePlan(data) {
      this.userFreePlan = data;
    },
    setSelectedPricingPlanPage(selectedPricingPlanPage) {
      this.selectedPricingPlanPage = selectedPricingPlanPage;
    },
    unsetPlans() {
      this.plans = null;
    },
    unsetPlanSelected() {
      this.selectedPricingPlanPage = null;
    },
  },
  getters: {
    hasToLoadPreview(state) {
      return state.isLoadingPreview;
    },
    isCouponFormOpen: (state) => {
      return state.couponFormIsOpen;
    },
    /**
     * Determines if the user is eligible to upgrade their subscription
     * @returns {boolean} True if the user can upgrade their subscription, false otherwise
     */
    canUpgradeSubscription() {
      if (this.isManualUserWithActiveTrial || this.isFsUserWithActiveTrial) {
        return true;
      }

      if (this.hasProSubscription) {
        return !this.isSubscriptionInTrialingStatus;
      }

      return !(this.hasProSubscription || this.hasLifetimeSubscription);
    },
    // TODO: this value can be undefined if the list of plans is not loaded
    // we should improve this that could be prone to errors
    currentPlan(state) {
      const { isGuestUser } = storeToRefs(useAuthStore());
      if (isGuestUser.value) return null;
      if (!this.hasActiveSubscription) return this.freePlan;

      return state.plans?.find((plan) => {
        return plan.title === state.userSubscription.planName;
      });
    },
    currentPlanName() {
      return this.currentPlan?.title;
    },
    /**
     * @deprecated - use hasFreeSubscription instead
     */
    isUserFreePlan: (state) => {
      return state.userFreePlan;
    },
    hasActiveSubscription: (state) => {
      return state.userSubscription?.hasActivePaidSubscription;
    },
    /**
     * @deprecated - use hasFreeSubscription instead
     */
    hasFreeTrial() {
      return !this.hasActiveSubscription;
    },
    hasFreeSubscription() {
      return !this.hasActiveSubscription;
    },
    hasLifetimeSubscription: (state) => {
      return !!state.userSubscription?.hasLifetimeSubscription;
    },
    hasProSubscription(state) {
      if (!this.hasActiveSubscription) return false;

      return state.userSubscription?.planName === getPlanNames().PRO;
    },
    isSubscriptionInGracePeriod: (state) => {
      return !!state.userSubscription?.cancelledAt;
    },
    isSubscriptionInPastDueStatus: (state) => {
      return state.userSubscription?.status === PAST_DUE_STATUS;
    },
    isSubscriptionInTrialingStatus: (state) => {
      return state.userSubscription?.status === TRIALING_STATUS;
    },
    isManualUserWithActiveTrial() {
      const isManualUser = useAuthStore().isManualUser;
      const isFluoroSafetyUser = useAuthStore().isFluoroSafetyUser;

      return this.hasProSubscription && this.isSubscriptionInTrialingStatus && isManualUser && !isFluoroSafetyUser;
    },
    isFsUserWithActiveTrial() {
      const { isManualUser, isFluoroSafetyUser } = storeToRefs(useAuthStore());

      return (
        this.hasProSubscription &&
        this.isSubscriptionInTrialingStatus &&
        isFluoroSafetyUser.value &&
        !isManualUser.value
      );
    },
    subscriptionEndDate: (state) => {
      return state.userSubscription?.endAt;
    },
    subscriptionCancelledDate: (state) => {
      return state.userSubscription?.cancelledAt;
    },
    hasPreviouslyRedeemedFluoroSafetyUpsellCoupon: (state) => {
      return state.userSubscription?.hasPreviouslyRedeemedFluoroSafetyUpsellCoupon ?? false;
    },
    // When the user is in the trial period, we also include the current plan
    availablePlansSummaryForUpsell(state) {
      if (this.canUpgradeSubscription && state.plans) {
        let plansToShow = state.plans;

        // TODO: delete the professional plan from the api response
        plansToShow = state.plans?.filter((plan) => plan.title !== 'Professional') ?? [];

        // it means is being accessed from the public pricing page
        if (!this.currentPlanName) {
          return plansToShow;
        }

        if (this.isManualUserWithActiveTrial || this.isFsUserWithActiveTrial) {
          return plansToShow;
        }

        return plansToShow?.filter((plan) => plan.title !== this.currentPlanName);
      } else if (this.freePlan) {
        return [this.freePlan];
      } else {
        return [];
      }
    },
    availablePlansToChange(state) {
      if (this.canUpgradeSubscription && state.plans) {
        let plansToShow = state.plans;

        // TODO: delete the professional plan from the api response
        plansToShow = state.plans?.filter((plan) => plan.title !== 'Professional') ?? [];

        // it means is being accessed from the public pricing page
        if (!this.currentPlanName) {
          return plansToShow;
        }

        return plansToShow?.filter((plan) => plan.title !== this.currentPlanName);
      } else if (this.freePlan) {
        return [this.freePlan];
      } else {
        return [];
      }
    },
    availablePlansToUpgrade() {
      const availablePlans = this.isSubscriptionInTrialingStatus
        ? this.availablePlansSummaryForUpsell
        : this.availablePlansToChange;

      return availablePlans?.filter((plan) => plan.title !== getPlanNames().FREE);
    },
    // TODO: move to a plans store
    freePlan(state) {
      return state.plans?.find((plan) => {
        return plan.isFree;
      });
    },
    proPlan(state) {
      return state.plans?.find((plan) => {
        return plan.title === getPlanNames().PRO;
      });
    },
    lifetimePlan(state) {
      return state.plans?.find((plan) => {
        return plan.title === getPlanNames().LIFETIME;
      });
    },
    planSelected: (state) => {
      return state.selectedPricingPlanPage;
    },
    /**
     * Calculates the total payment amount for the selected plan without applying any user-submitted coupon.
     * The total is adjusted by the current auto discount applied to the selected plan.
     */
    planSelectedTotalPaymentWithoutUserCoupon() {
      const amount = this.planSelected?.currentPlanPriceHash
        ? this.planSelected?.planOriginalBasePrice
        : this.planSelected?.priceWithDiscountByDefault;

      return amount - this.planSelectedCurrentAutoDiscount;
    },
    /**
     * Calculates the current auto discount applied to the selected plan.
     * This includes any remaining discounts or discounts due to manual non-fs periods.
     * In such cases, the discount is taken from the plan and not from the user-submitted coupon.
     */
    planSelectedCurrentAutoDiscount() {
      let amount = 0;

      amount += this.planSelected?.remainingAmount ?? 0;
      amount += this.planSelected?.discountByFluorosafety ?? 0;

      return amount;
    },
    canUpdateBillingInUpsellPage() {
      return (this.isManualUserWithActiveTrial || this.isFsUserWithActiveTrial) && this.planSelected?.title === 'Pro';
    },
  },
});
