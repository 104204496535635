/**
 * @file Router guards for managing user access and subscriptions.
 * @global
 *
 * Known Issue:
 * There is a known issue where these router guards may run before some Pinia stores
 * are fully initialized. This can cause problems if a beforeEnter guard in the router
 * uses a Pinia store and the user accesses the route directly via URL. A solution
 * for this issue has not been found yet.
 */

import { useAuthStore } from '@/store-v2/auth.store';
import { useSubscriptionsStore } from '@/store-v2/subscriptions.store';

export const ensureActiveSubscriptionGuard = (_to, _from, next) => {
  // NOW: known issue when refreshing pinia store not ready
  const { hasActiveSubscription } = useSubscriptionsStore();

  if (!hasActiveSubscription) {
    console.warn('User trying to access a page that requires a paid subscription, redirecting to home');
    next({ name: 'home' });
  } else {
    next();
  }
};

export const ensureUserIsGuest = (_to, _from, next) => {
  const isGuestUser = useAuthStore().isGuestUser;

  isGuestUser ? next() : next({ name: 'home' });
};

export const ensureValidPasswordResetToken = async (to, from, next) => {
  const authStore = useAuthStore();
  const email = to.params.email;
  const rawToken = to.params.token;
  const decodedToken = decodeURIComponent(rawToken);

  try {
    await authStore.verifyResetPasswordToken({ email, token: decodedToken });
    next();
  } catch (error) {
    next({ name: 'login', query: { nextRoute: from.meta?.nextRoute ?? to.query.nextRoute ?? 'home' } });
  }
};
