import type { App } from 'vue';
import type { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';

export const initSentry = ({ app, router, env }: { app: App; router: Router; env: Record<string, string> }) => {
  const tracesSampleRate = Number(env.VITE_SENTRY_TRACES_SAMPLE_RATE) ?? 0;
  const replaysSessionSampleRate = Number(env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE) ?? 0;
  const replaysOnErrorSampleRate = Number(env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE) ?? 0;

  Sentry.init({
    app,
    dsn: env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
      }),
    ],
    tracePropagationTargets: ['localhost', '*.acapedia.com', /^\//],
    tracesSampleRate,
    environment: env.VITE_SENTRY_ENVIRONMENT ?? 'local',
    release: `${env.VITE_APP_VERSION}`,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  });
};
