import { defineAsyncComponent, h } from 'vue';
import {
  toast as toastify,
  updateGlobalOptions,
  type ToastContainerOptions,
  type ToastOptions as ToastifyOptions,
} from 'vue3-toastify';

import '@/lib/toast/toast.scss';

const ToastSuccessIcon = defineAsyncComponent(() => import('@/components/toast/ToastSuccessIcon.vue'));
const ToastErrorIcon = defineAsyncComponent(() => import('@/components/toast/ToastErrorIcon.vue'));
const ToastInfoIcon = defineAsyncComponent(() => import('@/components/toast/ToastInfoIcon.vue'));
const ToastCloseIcon = defineAsyncComponent(() => import('@/components/toast/ToastCloseIcon.vue'));

const GLOBAL_OPTIONS: Partial<ToastContainerOptions> = {
  clearOnUrlChange: false,
  // NOW: This close icon is too small for mobile
  closeButton: (props) => h(ToastCloseIcon, props),
  limit: 3,
  position: toastify.POSITION.TOP_CENTER,
  theme: toastify.THEME.COLORED,
  transition: toastify.TRANSITIONS.SLIDE,
};

updateGlobalOptions(GLOBAL_OPTIONS);

export type ToastOptions = {
  /**
   * Delay in ms to close the toast. If set to false, the notification needs to be closed manually
   */
  autoClose?: number | boolean;

  /**
   * Unique identifier for the toast notification. Used to remove specific toasts or prevent duplicates, among others.
   */
  toastId?: string;
};

export type AppToast = {
  error: (message: string, options?: ToastOptions) => void;
  success: (message: string, options?: ToastOptions) => void;
  info: (message: string, options?: ToastOptions) => void;
  clearAll: () => void;
};

// Generally, use GLOBAL_OPTIONS. DEFAULT_OPTIONS is used to override settings not available in GLOBAL_OPTIONS.
const DEFAULT_OPTIONS: ToastifyOptions = {
  autoClose: 3000,
};

/**
 * A wrapper around `vue3-toastify` for displaying toast notifications.
 *
 * @example
 * // Display a success toast
 * appToast.success('Operation successful!', { autoClose: 2000 });
 *
 * @example
 * // Display an error toast
 * appToast.error('An error occurred!', { autoClose: false });
 *
 * @example
 * // Clear all toast notifications
 * appToast.clearAll();
 */
export const appToast = {
  /**
   * Display a success toast notification.
   *
   * @param {string} message - The message to display in the toast.
   * @param {ToastOptions} [options={ autoClose: 3000 }] - Optional toast configuration.
   *
   * @example
   * appToast.success('Data saved successfully',
   *  { autoClose: 5000 }
   * );
   */
  success(message: string = 'Success!', options: ToastOptions = {}) {
    toastify.success(message, {
      ...DEFAULT_OPTIONS,
      icon: h(ToastSuccessIcon),
      ...options,
    });
  },

  /**
   * Display an error toast notification.
   *
   * @param {string} message - The message to display in the toast.
   * @param {ToastOptions} [options={}] - Optional toast configuration.
   *
   * @example
   * appToast.error('Failed to save data',
   *  { autoClose: false }
   * );
   */
  error(message: string = 'Oops.. Something Went Wrong..', options: ToastOptions = {}) {
    toastify.error(message, {
      ...DEFAULT_OPTIONS,
      autoClose: 5000,
      icon: h(ToastErrorIcon),
      ...options,
    });
  },

  /**
   * Display an info toast notification.
   *
   * @param {string} message - The message to display in the toast.
   * @param {ToastOptions} [options={ autoClose: 3000 }] - Optional toast configuration.
   *
   * @example
   * appToast.info('Informational message', { autoClose: 5000 });
   */
  info(message: string = 'Informational message', options: ToastOptions = {}) {
    toastify.info(message, {
      ...DEFAULT_OPTIONS,
      icon: h(ToastInfoIcon),
      ...options,
    });
  },

  /**
   * Clear all toast notifications currently displayed.
   *
   * @example
   * appToast.clearAll();
   */
  clearAll() {
    toastify.clearAll();
  },
};
