import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

import { useEventNotifier } from '@/composables/useEventNotifier.ts';

import { useProfileStore } from '@/store-v2/profile.store.js';

import {
  doChangePassword,
  doCheckEmailExistence,
  doCheckEmailForResetPassword,
  doCheckPassword,
  doConfirmDeleteCode,
  doGetStates,
  doLogin,
  doLoginWithSocialProvider,
  doLogout,
  doRegister,
  doRegisterWithSocialProvider,
  doSendCodeToEmail,
  doSendDeleteCode,
  doSendPasswordReset,
  doVerifyEmailCode,
  doVerifyResetPasswordToken,
  getUser,
} from '@/api/auth';
import { isSuccessfulResponse } from '@/api/utilities.ts';

const AUTH_TOKEN_STORAGE_KEY = 'authToken';

/**
 * @typedef {import('../api/types.d.ts').User} User
 */

/**
 * @typedef {Object} AuthState
 * @property {string|null} authToken
 * @property {User|null} user
 * @property {boolean} isManualUser
 * @property {boolean} isFluoroSafetyUser
 * @property {string|null} guestHashSession
 * @property {boolean} isLoadingSignup
 */

export const useAuthStore = defineStore('auth', {
  /**
   * @returns {AuthState}
   */
  state: () => ({
    authToken: useStorage(AUTH_TOKEN_STORAGE_KEY, null),
    user: null,
    isManualUser: false,
    isFluoroSafetyUser: false,
    guestHashSession: localStorage.getItem('acapediaGuestHashSession') ?? null,
    isLoadingSignup: false,
  }),
  actions: {
    async login(params) {
      const loginResult = await doLogin(params);
      const { setUser: setUserEventNotifier } = useEventNotifier();
      setUserEventNotifier(loginResult.user);

      await this.initializeSession(loginResult.token);
    },
    setLocalStorageTokenFromUrlToken(params) {
      return this.initializeSession(params.token);
    },
    async loginWithSocialProvider(params) {
      const loginResult = await doLoginWithSocialProvider(params);
      await this.initializeSession(loginResult.token);
    },
    registerWithSocialProvider({ providerName, providerResponse }) {
      return doRegisterWithSocialProvider({ providerName, providerResponse });
    },
    async initializeSession(token) {
      this.setAuthToken(token);
      await this.bootstrapUserData();
    },
    async bootstrapUserData() {
      const { setUser: setUserEventNotifier } = useEventNotifier();

      if (!this.authToken) {
        setUserEventNotifier();
        return;
      }

      const user = await this.getUser();
      // In case of unauthenticated user, we don't fetch the following data
      // As they are breaking the app on refresh (a blank page)
      if (!user) return;

      const profileStore = useProfileStore();

      // @circular-ignore
      // To avoid circular dependency, we are importing the store here
      const { useSubscriptionsStore } = await import('./subscriptions.store.js');

      return Promise.all([
        profileStore.getProfile(),
        profileStore.checkIfPaymentMethodWillExpire(),
        useSubscriptionsStore().getUserSubscription(),
      ]);
    },
    async getUser() {
      if (!this.authToken) return;

      try {
        const response = await getUser();

        if (isSuccessfulResponse(response)) {
          const { setUser: setUserEventNotifier } = useEventNotifier();
          setUserEventNotifier(response.data);
          this.setUser(response.data);
          this.setTypeOfUser(response.data);

          return response.data;
        }
      } catch (error) {
        this.unsetUser();
      }
    },
    async logout() {
      await doLogout();
      const { unsetUser: unsetUserEventNotifier } = useEventNotifier();
      unsetUserEventNotifier();
      this.localLogout();
    },
    async localLogout() {
      this.unsetUser();
      this.unsetAuthToken();

      const profileStore = useProfileStore();
      profileStore.unsetProfile();
      profileStore.unsetPaymentWillExpire();

      // To avoid circular dependency, we are importing the store here
      const { useSpecialtiesStore } = await import('@/store-v2/specialties.store.js');
      useSpecialtiesStore().updateSpecialtiesFromCustomizer({
        specialties: [],
        subSpecialties: [],
      });

      localStorage.removeItem('acapedia_user_visited_articles');
      localStorage.removeItem('acapedia_taken_quizzes_by_guest_user');
      localStorage.removeItem('acapedia_guest_quizzes');
      localStorage.removeItem('acapediaGuestHashSession');
      localStorage.removeItem('acapedia_special_requirements_selected_state');

      // @circular-ignore
      const { useSubscriptionsStore } = await import('@/store-v2/subscriptions.store.js');
      const subscriptionsStore = useSubscriptionsStore();
      subscriptionsStore.removeUserSubscription();
      subscriptionsStore.unsetPlans();
      subscriptionsStore.unsetPlanSelected();
    },
    checkPassword(payload) {
      return doCheckPassword(payload);
    },
    changePassword(payload) {
      return doChangePassword(payload);
    },
    emailExists(payload) {
      return doCheckEmailExistence(payload);
    },
    emailExistsForResetPassword(payload) {
      return doCheckEmailForResetPassword(payload);
    },
    sendPasswordReset(payload) {
      return doSendPasswordReset(payload);
    },
    verifyResetPasswordToken(payload) {
      return doVerifyResetPasswordToken(payload);
    },
    async register(params) {
      const guestQuizzes = JSON.parse(localStorage.getItem('acapedia_guest_quizzes')) ?? [];
      if (guestQuizzes.length) {
        params = {
          ...params,
          guestQuizzes: guestQuizzes,
          userGuestHash: localStorage.getItem('acapediaGuestHashSession') ?? null,
        };
      }

      const response = await doRegister(params);

      if (isSuccessfulResponse(response)) {
        // Not ideal to access data.data, but
        // we wrapped the response in a data object because
        // we need extra info (metadata, like hashed information) for social providers
        // separate from the user object
        const responseBody = response.data.data;
        const { user, token } = responseBody;

        const { setUser: setUserEventNotifier } = useEventNotifier();
        setUserEventNotifier(user);
        localStorage.removeItem('acapedia_guest_quizzes');

        this.setUser(user);
        await this.initializeSession(token);

        return responseBody;
      }
    },
    sendDeleteCode(payload) {
      return doSendDeleteCode(payload);
    },
    async confirmDeleteCode(payload) {
      const response = await doConfirmDeleteCode(payload);

      if (isSuccessfulResponse(response)) {
        this.setUser(null);

        this.setAuthToken(null);
      }

      return response;
    },
    sendCodeToEmail() {
      return doSendCodeToEmail();
    },
    verifyEmailCode(payload) {
      return doVerifyEmailCode(payload);
    },
    getStates() {
      return doGetStates();
    },
    setAuthToken(token) {
      this.authToken = token;
    },
    setUser(data) {
      this.user = data;
    },
    setTypeOfUser(data) {
      if (data.is_manual_user && data.acquisition_source === 'FluoroSafety') {
        this.isFluoroSafetyUser = true;
      } else if (data.is_manual_user) {
        this.isManualUser = true;
      }
    },
    unsetAuthToken() {
      this.authToken = null;
    },
    unsetUser() {
      this.user = null;
    },
    setGuestHashSession(hash) {
      this.guestHashSession = hash;
    },
    setLoadingSignup(isLoading) {
      this.isLoadingSignup = isLoading;
    },
  },
  getters: {
    currentUser: (state) => {
      return state.user;
    },
    isLoggedInUser: (state) => {
      return !!state.authToken;
    },
    hasUnAwardedCredits: (state) => {
      return state.user?.unawardedCredits > 0;
    },
    isGuestUser() {
      return !this.isLoggedInUser;
    },
  },
});
