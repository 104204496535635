import truncateHtml from 'truncate-html';

export const isProductionEnv = import.meta.env.VITE_ENVIRONMENT === 'production';
export const isPreviewEnv = import.meta.env.VITE_ENVIRONMENT === 'preview';
export const isDevelopmentEnv = import.meta.env.VITE_ENVIRONMENT === 'development';
export const isTestingEnv = import.meta.env.VITE_ENVIRONMENT === 'testing';
export const isContinuousIntegrationEnv = import.meta.env.VITE_ENVIRONMENT === 'ci';

export const generateUUIDv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Truncates the input text to the specified maximum length, without taking into account HTML tags.
 *
 * @param {string} inputText - The text to truncate.
 * @param {number} maxLength - The maximum length of the truncated text.
 * @param {Object} options - The options object.
 * @param {string} [options.ellipsis] - The ellipsis to use when truncating the text.
 * @param {boolean} [options.stripTags] - Whether to strip HTML tags from the text.
 * @returns {string} The truncated text.
 */
export function truncateText(
  inputText,
  maxLength,
  { ellipsis, stripTags, keepWhitespaces } = {
    ellipsis: '...',
    stripTags: false,
    keepWhitespaces: false,
  },
) {
  const truncatedText = truncateHtml(inputText, maxLength, {
    ellipsis,
    stripTags,
    keepWhitespaces,
  });

  // We have to do this because truncateHtml is removing the self-closing slash
  return addSelfClosingSlash(truncatedText);
}

function addSelfClosingSlash(htmlString) {
  const selfClosingTags = [
    'br',
    'hr',
    'img',
    'input',
    'link',
    'meta',
    'area',
    'base',
    'col',
    'command',
    'embed',
    'keygen',
    'param',
    'source',
    'track',
    'wbr',
  ];

  const pattern = new RegExp(`<(${selfClosingTags.join('|')})\\b([^>]*)>`, 'gi');

  return htmlString.replace(pattern, `<$1$2/>`);
}

export function createCroppedCanvasDataURL(sourceCanvas, crop) {
  const croppedCanvas = document.createElement('canvas');
  croppedCanvas.width = crop.width;
  croppedCanvas.height = crop.height;

  const ctx = croppedCanvas.getContext('2d');
  ctx.drawImage(
    sourceCanvas,
    crop.x,
    crop.y, // Start clipping
    crop.width,
    crop.height, // Width and height of clipped rectangle
    0,
    0, // Place the clipped part at the top left corner of the new canvas
    crop.width,
    crop.height, // Size of the canvas (same as clipped rectangle)
  );

  return croppedCanvas.toDataURL('image/png');
}
