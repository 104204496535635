import axios from 'axios';

import { getResponseDataNotWrapped, getResponseDataVOld, responseHandler, toastHandler } from '@/api/utilities.ts';

const doLogin = (form) => {
  return responseHandler(axios.post('/login', form));
};

const doLoginWithSocialProvider = ({ providerName, providerResponse }) => {
  return responseHandler(axios.post(`/socialite/${providerName}`, providerResponse));
};

const doRegisterWithSocialProvider = ({ providerName, providerResponse }) => {
  return responseHandler(axios.post(`/socialite/register/${providerName}`, providerResponse), {
    responseDataGetter: getResponseDataVOld,
  });
};

const getUser = () => {
  return axios.get('/user');
};

const doLogout = () => {
  return responseHandler(axios.post('/logout'));
};

const doCheckPassword = (params) => {
  return responseHandler(axios.post('/check-password', params), {
    responseDataGetter: getResponseDataNotWrapped,
    hasToShowSuccessToasts: false,
  });
};

const doChangePassword = (params) => {
  return responseHandler(axios.post('/confirm-password', params), {
    responseDataGetter: getResponseDataVOld,
    hasToShowToasts: false,
  });
};

const doSendPasswordReset = (params) => {
  return axios.post('/send-password-reset', params);
};

const doCheckEmailExistence = (params) => {
  return responseHandler(axios.post('/email-exists', params), {
    responseDataGetter: getResponseDataVOld,
  });
};

const doCheckEmailForResetPassword = (params) => {
  return axios.post('/email-exists-reset-password', params);
};

const doVerifyResetPasswordToken = (params) => {
  return responseHandler(axios.post('/password-reset/token', params), {
    responseDataGetter: getResponseDataVOld,
    hasToShowToasts: false,
  });
};

const doRegister = (params) => {
  return responseHandler(axios.post('/register', params), {
    responseDataGetter: getResponseDataVOld,
  });
};

const doSendDeleteCode = () => {
  return toastHandler(axios.post('/user/email/code/delete'), true);
};

const doConfirmDeleteCode = (params) => {
  return toastHandler(axios.post('/user/email/code/delete/verify', params), true);
};

const doSendCodeToEmail = () => {
  return toastHandler(axios.post('/user/email/code'), true);
};

const doVerifyEmailCode = (params) => {
  return toastHandler(axios.post('/user/email/code/verify', params), true);
};

const doGetStates = () => {
  return responseHandler(axios.get('/states'), {
    responseDataGetter: getResponseDataNotWrapped,
    hasToShowSuccessToasts: false,
  });
};

export {
  doLogin,
  doLoginWithSocialProvider,
  doRegisterWithSocialProvider,
  getUser,
  doLogout,
  doCheckPassword,
  doChangePassword,
  doSendPasswordReset,
  doCheckEmailExistence,
  doCheckEmailForResetPassword,
  doVerifyResetPasswordToken,
  doRegister,
  doSendDeleteCode,
  doConfirmDeleteCode,
  doSendCodeToEmail,
  doVerifyEmailCode,
  doGetStates,
};
