// IMPORTANT: Please remember to update this file whenever a new feature flag is added.
// Add the new feature flag as a constant by defining it here and include it in the featureFlags array below.
// Also, make sure to remove any feature flags from this file that are no longer being used in the code.

export const MILESTONE_ONE_FEED_ENABLED = 'MILESTONE_ONE_FEED_ENABLED';
export const MILESTONE_PRO_HITS_CREDIT_LIMIT_ENABLED = 'MILESTONE_PRO_HITS_CREDIT_LIMIT_ENABLED';
export const MILESTONE_NEW_ABSTRACT_LAYOUT = 'MILESTONE_NEW_ABSTRACT_LAYOUT';
export const MILESTONE_INTELLIGENT_ARTICLE_ABSTRACT_ENABLED = 'MILESTONE_INTELLIGENT_ARTICLE_ABSTRACT_ENABLED';

export const FEATURE_BRANDED_APP_BAR_ENABLED = 'FEATURE_BRANDED_APP_BAR_ENABLED';
FEATURE_BRANDED_APP_BAR_ENABLED;

// Every feature flag should be added to this array, to be considered by the feature flag service.
export const FEATURE_FLAGS = [
  MILESTONE_ONE_FEED_ENABLED,
  FEATURE_BRANDED_APP_BAR_ENABLED,
  MILESTONE_NEW_ABSTRACT_LAYOUT,
  MILESTONE_INTELLIGENT_ARTICLE_ABSTRACT_ENABLED,
] as const;

// These feature flags are always off, regardless of the feature flag service status.
export const DISABLED_FEATURE_FLAGS = [MILESTONE_ONE_FEED_ENABLED];

// These feature flags are always on, regardless of the feature flag service status.
export const ALWAYS_ON_FEATURE_FLAGS = [];
