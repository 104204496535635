import { createPinia, PiniaVuePlugin } from 'pinia';
import { createSentryPiniaPlugin } from '@sentry/vue';

const pinia = createPinia();
const store = pinia;

pinia.use(
  createSentryPiniaPlugin({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stateTransformer: (state: Record<string, any>) => {
      const transformedState = {
        ...state,
        user: {
          ...state.user,
          password: '********',
        },
      };

      return transformedState;
    },
  }),
);

export { pinia, store, PiniaVuePlugin };
