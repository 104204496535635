import { getOrCreateDeviceId } from '@/lib/deviceId';

import { FeatureFlagAlwaysOnImpl } from './FeatureFlagAlwaysOnImpl';
import { ALWAYS_ON_FEATURE_FLAGS, DISABLED_FEATURE_FLAGS, FEATURE_FLAGS } from './featureFlags';
import { FeatureFlagSplitIoImpl } from './FeatureFlagSplitIoImpl';

let instance = null;
export class FeatureFlagProvider {
  #featureFlagProviderImpl = null;

  constructor(featureFlagProviderImpl) {
    this.#featureFlagProviderImpl = featureFlagProviderImpl;
  }

  async init({ deviceId = getOrCreateDeviceId() } = {}) {
    await this.#featureFlagProviderImpl.init({ deviceId });
  }

  isFeatureEnabled(featureFlag) {
    if (DISABLED_FEATURE_FLAGS.includes(featureFlag)) {
      return false;
    }

    const isAlwaysOnFeatureFlag = ALWAYS_ON_FEATURE_FLAGS.includes(featureFlag);
    if (isAlwaysOnFeatureFlag) {
      console.info(`Feature flag "${featureFlag}" is always on.`);
      return true;
    }

    const isListedFeatureFlag = FEATURE_FLAGS.includes(featureFlag);
    if (!isListedFeatureFlag) {
      console.warn(`Feature flag "${featureFlag}" is not listed in the feature flags list.`);
      return false;
    }

    if (!this.#featureFlagProviderImpl.isReady()) {
      console.error(
        'You are calling isFeatureEnabled but the FeatureFlagProvider is not ready yet, did you forget to call init()? You may have async issues.',
      );

      return false;
    }

    const hasToTurnOffAllFeatureFlags = import.meta.env.VITE_TURN_OFF_ALL_FEATURE_FLAGS === 'true';
    if (hasToTurnOffAllFeatureFlags) {
      console.warn('All feature flags are turned off, by setting VITE_TURN_OFF_ALL_FEATURE_FLAGS to true.');

      return false;
    }

    return this.#featureFlagProviderImpl.isFeatureEnabled(featureFlag);
  }
}

export function getFeatureFlagProvider() {
  if (!instance) {
    const hasToUseFeatureFlagService = import.meta.env.VITE_USE_FEATURE_FLAG_SERVICE === 'true';
    const featureFlagImpl = hasToUseFeatureFlagService ? new FeatureFlagSplitIoImpl() : new FeatureFlagAlwaysOnImpl();
    instance = new FeatureFlagProvider(featureFlagImpl);

    // For testing purposes using the devtools
    window.featureFlagProvider = instance;
  }

  return instance;
}

export function resetFeatureFlagProvider() {
  instance = null;
}
