<template>
  <v-app :class="applicationBackground">
    <router-view v-slot="{ Component }">
      <keep-alive v-if="$route.meta?.keepAlive">
        <component :is="Component" />
      </keep-alive>
      <component
        :is="Component"
        v-else
      />
    </router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    applicationBackground: 'background-base',
  }),
  head: {
    // if no sub-components specify the meta title, this one will be used
    title: 'Earn CME and MOC with Acapedia',
  },
};
</script>
<style lang="scss">
// TODO: move this to the layout

// NOW: import this in another place
@use './styles/main.scss';
@use './styles/variables.scss' as *;

.background-pretest .v-application__wrap {
  background-color: transparent !important;
}

.background-base .v-application__wrap {
  background-color: var(--v-background-base) !important;
}
</style>
