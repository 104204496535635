import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

import {
  doAgreeActivityDisclosure,
  doCheckIfActivityDisclosureShouldBeShown,
  doGetArticle,
  doGetArticleQuiz,
  doGetEvaluation,
  doGetMostRecentActiveActivityByArticleId,
  doGetQuizResults,
  doPostDownVoteArticle,
  doPostPretest,
  doPostQuizAnswer,
  doPostUpVoteArticle,
  doSaveEvaluation,
  doSavePretestStarted,
  doSaveQuizUserFinished,
  doSaveQuizUserStarted,
  doUpdateQuizStatus,
  doUpdateShowPretestOnboarding,
} from '@/api/article';
import { isSuccessfulResponse } from '@/api/utilities.ts';

const GUEST_QUIZZES_LOCAL_STORAGE_KEY = 'acapedia_guest_quizzes';

// TODO: to extend each of the objects and arrays of the Article typedef
/**
 * @typedef {Object} Article
 * @property {boolean|null} specialRequirement - Indicates the presence of a special requirement.
 * @property {Array|null} creditTypes - Array of credit types associated with the article.
 * @property {Array|null} boards - Array of boards associated with the article.
 */

// TODO: to extend each of the objects and arrays of the ArticleState typedef
/**
 * @typedef {Object} ArticleState
 * @property {Article|null} article - The article data.
 * @property {Object|null} articleQuiz - The quiz data associated with the article.
 * @property {boolean} isLoadingArticle - Flag to indicate if the article loading state.
 * @property {boolean} isShowingResultPage - Flag to indicate if the result page is being displayed.
 * @property {boolean} isShowingResultDetailsPage - Flag to indicate if the article's detailed result page is being displayed.
 * @property {boolean} shouldShowEvaluation - Flag to indicate if evaluations should be shown.
 * @property {number} currentReadingAtArticleId - The ID of the article that is currently being read.
 * @property {boolean} isShowingActivityDisclosure - Flag to indicate if the activity disclosure should be shown.
 * @property {boolean} hasToHidePretestWelcomeByUserPreference
 */
export const useArticlesStore = defineStore('articles', {
  /**
   * @returns {ArticleState}
   */
  state: () => ({
    article: null,
    articleQuiz: null,
    isLoadingArticle: false,
    loadingArticlePromise: null,
    isShowingResultPage: false,
    isShowingResultDetailsPage: false,
    shouldShowEvaluation: true,
    currentReadingAtArticleId: 0,
    isShowingActivityDisclosure: false,
    hasToHidePretestWelcomeByUserPreference: useStorage('hasToHidePretestWelcomeByUserPreference', false),
  }),
  actions: {
    /**
     * @deprecated Use the useArticleDetails composable instead
     */
    async fetchArticle({ id, slug, topicSlug, stateSlug }) {
      if (this.isLoadingArticle) return this.loadingArticlePromise;

      this.isLoadingArticle = true;
      try {
        this.loadingArticlePromise = doGetArticle({
          id,
          slug,
          topicSlug,
          stateSlug,
        });

        const response = await this.loadingArticlePromise;

        if (response?.id) {
          const previousVisitedArticles = JSON.parse(localStorage.getItem('acapedia_user_visited_articles')) ?? [];

          localStorage.setItem(
            'acapedia_user_visited_articles',
            JSON.stringify(Array.from(new Set([...previousVisitedArticles, parseInt(response.id)]))),
          );

          this.article = response;
        }

        return response;
      } catch (error) {
        console.error('Error fetching article:', error);
        throw error;
      } finally {
        this.isLoadingArticle = false;
        this.loadingArticlePromise = null;
      }
    },
    async fetchArticleQuiz(payload) {
      if (!this.article) {
        await this.fetchArticle({
          id: payload.articleId,
          topicSlug: payload.topicSlug,
          stateSlug: payload.stateSlug,
        });
      }

      const articleId = payload?.articleId;
      let article = null;
      const quizPreviouslyStoredByGuest = await this.findGuestQuizHash({
        articleId,
        topicSlug: payload?.topicSlug,
        stateSlug: payload?.stateSlug,
      });

      const requestParams = { params: { id: articleId, quizPreviouslyStoredByGuest: quizPreviouslyStoredByGuest } };

      const topicSlug = payload?.topicSlug;
      const stateSlug = payload?.stateSlug;

      if (topicSlug && stateSlug) {
        requestParams.params.topicSlug = topicSlug;
        requestParams.params.stateSlug = stateSlug;
      }

      const response = await doGetArticleQuiz(requestParams);

      if (isSuccessfulResponse(response)) {
        article = response.data;

        if (article.guestQuiz && !quizPreviouslyStoredByGuest) {
          const previousGuestQuizzes = JSON.parse(localStorage.getItem(GUEST_QUIZZES_LOCAL_STORAGE_KEY)) ?? [];

          const topicSlug = payload?.topicSlug;
          const stateSlug = payload?.stateSlug;

          // We only keep one SR quiz per user at a time
          const nonSrGuestQuizzes = previousGuestQuizzes.filter((quiz) => !quiz.topicSlug && !quiz.stateSlug);

          localStorage.setItem(
            GUEST_QUIZZES_LOCAL_STORAGE_KEY,
            JSON.stringify([
              ...nonSrGuestQuizzes,
              {
                quizId: article.quizId.toString(),
                hash: article.guestQuiz,
                articleId: article.articleId.toString(),
                startedAt: new Date().toISOString(),
                topicSlug,
                stateSlug,
              },
            ]),
          );
        }

        article.guestQuiz = article.guestQuiz ?? quizPreviouslyStoredByGuest;
      }

      this.articleQuiz = article;

      return article;
    },
    async fetchArticleQuizForPretest({ articleId, articleSlug }) {
      let article = null;
      const params = articleId ? { params: { id: articleId } } : { params: { slug: articleSlug } };

      const response = await doGetArticleQuiz(params);

      if (isSuccessfulResponse(response)) {
        article = response.data;
      }

      return article;
    },

    setHasToHidePretestWelcomeByUserPreference(value) {
      this.hasToHidePretestWelcomeByUserPreference = value;
      localStorage.setItem('hasToHidePretestWelcomeByUserPreference', JSON.stringify(value));
    },

    postQuizAnswer(payload) {
      return doPostQuizAnswer(payload);
    },
    updateQuizAnswer(payload) {
      return doPostQuizAnswer(payload);
    },
    getQuizResults(payload) {
      return doGetQuizResults(payload);
    },
    upVoteArticle(articleId) {
      return doPostUpVoteArticle(articleId);
    },
    downVoteArticle(articleId) {
      return doPostDownVoteArticle(articleId);
    },
    updateQuizStatus(quizId) {
      return doUpdateQuizStatus(quizId);
    },
    findGuestQuizHash({ articleId, topicSlug, stateSlug }) {
      const previousGuestQuizzes = JSON.parse(localStorage.getItem(GUEST_QUIZZES_LOCAL_STORAGE_KEY)) ?? [];

      const guestQuiz = previousGuestQuizzes.find(
        (guestQuiz) =>
          guestQuiz.articleId === articleId.toString() &&
          guestQuiz.topicSlug === topicSlug &&
          guestQuiz.stateSlug === stateSlug,
      );

      return guestQuiz?.hash ?? null;
    },
    postPretest(payload) {
      return doPostPretest(payload);
    },
    UpdateShowPretestOnboarding() {
      return doUpdateShowPretestOnboarding();
    },
    saveQuizUserStarted(payload) {
      return doSaveQuizUserStarted(payload);
    },
    saveQuizUserFinished(payload) {
      return doSaveQuizUserFinished(payload);
    },
    savePretestStarted(payload) {
      return doSavePretestStarted(payload);
    },
    getEvaluation(payload) {
      return doGetEvaluation(payload);
    },
    saveEvaluation(payload) {
      return doSaveEvaluation(payload);
    },
    async agreeActivityDisclosure(payload) {
      const response = await doAgreeActivityDisclosure(payload);
      if (response.success) {
        localStorage.setItem('acapediaGuestHashSession', response.hashId);
      }

      return response;
    },
    checkIfActivityDisclosureShouldBeShown(payload) {
      return doCheckIfActivityDisclosureShouldBeShown(payload);
    },
    getMostRecentActiveActivityByArticleId(payload) {
      return doGetMostRecentActiveActivityByArticleId(payload);
    },
    removeGuestQuizzesForSpecialRequirements() {
      const guestQuizzes = JSON.parse(localStorage.getItem(GUEST_QUIZZES_LOCAL_STORAGE_KEY)) ?? [];

      const nonSrGuestQuizzes = guestQuizzes.filter((quiz) => !quiz.topicSlug && !quiz.stateSlug);

      localStorage.setItem(GUEST_QUIZZES_LOCAL_STORAGE_KEY, JSON.stringify(nonSrGuestQuizzes));
    },
    setIsShowingResultPage(isShowingResultPage) {
      this.isShowingResultPage = isShowingResultPage;
    },
    setIsShowingResultDetailsPage(isShowingResultDetailsPage) {
      this.isShowingResultDetailsPage = isShowingResultDetailsPage;
    },
    setShouldShowEvaluation(shouldShowEvaluation) {
      this.shouldShowEvaluation = shouldShowEvaluation;
    },
    setCurrentReadingAtArticleId(data) {
      this.currentReadingAtArticleId = data;
    },
    setIsShowingActivityDisclosure(data) {
      this.isShowingActivityDisclosure = data;
    },
  },
  getters: {
    getCurrentReadingAtArticleId: (state) => {
      return state.currentReadingAtArticleId;
    },
  },
});
