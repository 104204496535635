import type { App } from 'vue';

import { appToast } from '@/lib/toast/toast.ts';

/**
 * Vue plugin that integrates `appToast` for toast notifications in components.
 * Provides access to notification methods via `this.$appToast` (Options API)
 * and `inject('appToast')` (Composition API).
 *
 * @example
 * // Usage inside a Vue component (Options API)
 * export default {
 *   mounted() {
 *     // Show a success toast
 *     this.$appToast.success('Operation successful');
 *
 *     // Show an error toast
 *     this.$appToast.error('An error occurred');
 *
 *     // Clear all active toasts
 *     this.$appToast.clearAll();
 *   }
 * }
 *
 * @example
 * // Usage inside a Vue component (Composition API)
 * <script setup lang="ts">
 * import { inject } from 'vue';
 * import { type AppToast } from '@/lib/toast/toast.ts';
 *
 * // Force cast to ensure appToast is always provided
 * const appToast = inject('appToast') as AppToast;
 *
 * // Simple method that triggers a success notification
 * const showSuccess = () => {
 *   appToast.success('Operation successful!');
 * };
 *
 * // Call the function to display the success message
 * showSuccess();
 * </script>
 */
export const ToastPlugin = {
  install(app: App) {
    /**
     * @deprecated Use `this.$appToast` within a component or our appToast lib directly.
     * If you are inside a component, prefer using `this.$appToast` for accessing the toast functionality.
     * For external usage or utility files, import `appToast` directly from the toast library.
     */
    app.config.globalProperties.$toasted = {
      global: {
        showSuccess({ message }: { message: string }) {
          appToast.success(message);
        },
        showError({ message }: { message: string }) {
          appToast.error(message);
        },
      },
      clear() {
        appToast.clearAll();
      },
    };

    app.config.globalProperties.$appToast = appToast;
    app.provide('appToast', appToast);
  },
};
